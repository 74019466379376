import React from "react"
import { ErrorMessage, FastField, Field } from "formik"
import { getError, isValidPeselv2 } from "../validators"
import { Input, Label } from "reactstrap"
import { TInputProps } from "../types/inputProps"

const PeselInput = (props: TInputProps) => {
    const { name, type, disabled, label, depend, hidden, shouldUpdate } = props

    const shoudUpdateProp = shouldUpdate ? {shouldUpdate: shouldUpdate} : {}; 

    const validateEmail = (value: string) => {
        let error;
        if (!value) {
          error = 'Pole jest wymagane!';
        } else if (!isValidPeselv2(value)) {
          error = 'PESEL jest niepoprawny!';
        }
        return error;
      }

    return (
        <div className="w-100">
            <Label className="sfa-form__label">
                {label}
            </Label>
            {depend ?
                <Field
                    component={CustomPeselInput}
                    name={name}
                    disabled={disabled}
                    {...shoudUpdateProp}
                    validate={validateEmail}
                    type={hidden ? 'hidden' : type}
                />
                :
                <FastField
                    component={CustomPeselInput}
                    name={name}
                    disabled={disabled}
                    {...shoudUpdateProp}
                    validate={validateEmail}
                    type={hidden ? 'hidden' : type}
                />
            }
        </div>
    )
}

export default PeselInput

const CustomPeselInput = ({
    field,
    form: { touched, errors },
    ...props
}: any) => {
    return (
        <div className={getError(field, touched, errors)  ? 'sfa-form__input--error' : ""}>
            <Input
                {...field}
                {...props}
            />
            <div className="sfa-form__error">
                <ErrorMessage name={field.name} />
            </div>
        </div>
    )
}