import React from 'react'
import CreatableSelect from 'react-select/creatable';
import { ErrorMessage, FastField } from "formik"
import { Label } from 'reactstrap';
import { getError } from '../helper/validators';
import { TInputProps } from '../types/inputProps';
import Tooltip from '../Tooltip';
import When from '../When';

const CreatableSelectInput = (props: TInputProps) => {
    const { name, disabled, options, placeholder, label, disableTooltip, shouldUpdate, tooltipText } = props

    const shoudUpdateProp = shouldUpdate ? {shouldUpdate: shouldUpdate} : {}; 

    return (
        <FastField
            name={name}
            label={label}
            {...shoudUpdateProp}
            tooltipText={tooltipText}
            disableTooltip={disableTooltip}
            component={CustomCreatableSelectInput}
            placeholder={placeholder}
            options={options || []}
            disabled={disabled} />
    )
}

export default CreatableSelectInput

const formatCreateLabel = (inputValue: string) => `Inna:  ${inputValue}`;

const CustomCreatableSelectInput = ({
    field,
    form: { touched, errors, setFieldValue, setFieldTouched },
    ...props
}: any) => (
    <div>
        <Label className="sfa-form__label">
            <div className="d-flex">
                {props.label}
                <When condition={props.tooltipText !== undefined}>
                    <Tooltip text={props.tooltipText || ""}>
                        <div className="form-tootltip">i</div>
                    </Tooltip>
                </When>
                <When condition={!props.disableTooltip}>
                    <Tooltip text="Jeżeli nie znajdujesz pasującej opcji wpisz ją i wciśnij enter.">
                        <div className="form-tootltip">?</div>
                    </Tooltip>
                </When>
            </div>
        </Label>
        <CreatableSelect
            {...field}
            {...props}
            isDisabled={props.disabled}
            onBlur={() => setTimeout(() => {setFieldTouched(field.name), 100})}
            value={props.options ? props.options.find((option: any) => option.value === field.value) : field.value}
            onChange={(option: any) => setFieldValue(field.name, option.value)}
            formatCreateLabel={formatCreateLabel}
            className={getError(field, touched, errors) ? 'react-select-container sfa-form__input--error' : "react-select-container"}
            classNamePrefix="react-select"
        />
        <div className="sfa-form__error">
            <ErrorMessage name={field.name} />
        </div>
    </div>
)