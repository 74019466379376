import {
  isValidIdentityCardNumber,
  isValidNip,
  isValidPesel,
  isValidRegon,
} from "helper/validators";
import { TOption } from "types/inputProps";
import * as Yup from "yup";
const REQUIRED_TEXT = "Pole jest wymagane!";

export const RegistrationFormSchema = Yup.object().shape({
  companyType: Yup.object().required("Forma działalności jest wymagana!"),
  nip: Yup.string()
    .test(
      "regex",
      'NIP jest niepoprawny! Pole musi składać się z 10 cyfr (może zawierać "-" jako separator)',
      (value) => {
        return isValidNip(value || "");
      }
    )
    .required("NIP jest wymagany!"),
  regon: Yup.string()
    .test(
      "regex",
      "Regon jest niepoprawny! Pole musi składać się z 9 cyfr.",
      (value) => {
        return isValidRegon(value || "");
      }
    )
    .required("Regon jest wymagany!"),
  companyName: Yup.string()
    .min(3, "Nazwa firmy musi mieć minimum 3 znaki!")
    .max(255, "Nazwa firmy nie może być dłuższa niż 255 znaków!")
    .required("Nazwa firmy jest wymagana!"),
  registeredAddress: Yup.object().shape({
    state: Yup.object().required("Pole województwo jest wymagane!"),
    province: Yup.object().required("Pole powiat jest wymagane!"),
    commune: Yup.object().required("Pole gmina jest wymagane!"),
    city: Yup.object().required("Pole miasto jest wymagane!"),
    postCode: Yup.string()
      .matches(/^[0-9]{2}-[0-9]{3}/, "Błędy kod kreskowy! Format XX-XXX")
      .required("Pole kod pocztowy jest wymagane!"),
    houseNumber: Yup.string()
      .max(10, "Numer budynku nie może się składać z więcej niż 10 znaków!")
      .required("Numer budynku jest wymagany!"),
    localNumber: Yup.string()
      .matches(
        /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
        "Numer lokalu może skłądać się z cyfr, liter i spacji."
      )
      .max(9, "Numer lokalu może mieć maksymalnie 9 znaków"),
  }),
  representatives: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string()
        .min(3, "Imię musi się składać z minimum 3 znaków!")
        .max(100, "Imię nie może się składać z więcej niż 100 znaków!")
        .matches(
          /^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ _]*$/,
          "Imię może zawierać tylko litery i spacje."
        )
        .required("Imię jest wymagane!"),
      lastName: Yup.string()
        .min(3, "Nazwisko musi się składać z minimum 3 znaków!")
        .max(150, "Nazwisko nie może się składać z więcej niż 150 znaków!")
        .matches(
          /^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ _-]*$/,
          "Nazwisko może zawierać tylko litery, spacje i myślink."
        )
        .required("Nazwisko jest wymagane!"),
      phone: Yup.string()
        .min(9, "Numer telefonu musi się składać z 9 cyfr")
        .max(9, "Numer telefonu musi się składać z 9 cyfr")
        .required("Numer telefonu jest wymagany!"),
      email: Yup.string()
        .email("Nieprawidłowy email!")
        .required("Email jest wymagany!"),
      pesel: Yup.string().test(
        "regex",
        "Pesel jest niepoprawny!",
        function (value) {
          // access to yup context
          // @ts-ignore
          const companyType =
            // @ts-ignore
            this.options?.from?.[1]?.value?.companyType?.value || "";
          if (companyType === "JDG" || companyType === "CIVIL") {
            if (value) {
              return isValidPesel(value);
            }
            return false;
          }
          return true;
        }
      ),
      identityCardNumber: Yup.string().test(
        "regex",
        "Numer dowodu jest niepoprawny!",
        function (value) {
          // access to yup context
          // @ts-ignore
          const companyType =
            // @ts-ignore
            this.options?.from?.[1]?.value?.companyType?.value || "";
          if (companyType === "JDG" || companyType === "CIVIL") {
            if (value) {
              return isValidIdentityCardNumber(value);
            }
            return false;
          }
          return true;
        }
      ),
      position: Yup.string()
        .min(2, "Stanowisko musi się składać z minimum 2 znaków!")
        .max(255, "Stanowisko nie może się składać z więcej niż 255 znaków!")
        .matches(
          /^[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ _.]*[A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ][A-Za-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ _.]*$/,
          "Nazwa stanowiska może zawierać tylko litery i spacje."
        )
        .required("Pole stanowisko jest wymagane!"),
      address: Yup.object().shape({
        state: Yup.object().required("Pole województwo jest wymagane!"),
        province: Yup.object().required("Pole powiat jest wymagane!"),
        commune: Yup.object().required("Pole gmina jest wymagane!"),
        city: Yup.object().required("Pole miasto jest wymagane!"),
        postCode: Yup.string()
          .matches(/^[0-9]{2}-[0-9]{3}/, "Błędy kod kreskowy! Format XX-XXX")
          .required("Pole kod pocztowy jest wymagane!"),
        houseNumber: Yup.string()
          .max(10, "Numer budynku nie może się składać z więcej niż 10 znaków!")
          .required("Numer budynku jest wymagany!"),
        localNumber: Yup.string()
          .matches(
            /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
            "Numer lokalu może skłądać się z cyfr, liter i spacji."
          )
          .max(9, "Numer lokalu może mieć maksymalnie 9 znaków"),
      }),
    })
  ),
  companyAddress: Yup.object().shape({
    state: Yup.object().when(
      "sameThenCompanyAddress",
      (sameThenCompanyAddress: boolean[]) => {
        if (!sameThenCompanyAddress[0]) {
          return Yup.object().required("Pole województwo jest wymagane!");
        }
        return Yup.object().nullable();
      }
    ),
    province: Yup.object().when(
      "sameThenCompanyAddress",
      (sameThenCompanyAddress: boolean[]) => {
        if (!sameThenCompanyAddress[0]) {
          return Yup.object().required("Pole powiat jest wymagane!");
        }
        return Yup.object().nullable();
      }
    ),
    commune: Yup.object().when(
      "sameThenCompanyAddress",
      (sameThenCompanyAddress: boolean[]) => {
        if (!sameThenCompanyAddress[0]) {
          return Yup.object().required("Pole gmina jest wymagane!");
        }
        return Yup.object().nullable();
      }
    ),
    city: Yup.object().when(
      "sameThenCompanyAddress",
      (sameThenCompanyAddress: boolean[]) => {
        if (!sameThenCompanyAddress[0]) {
          return Yup.object().required("Pole miasto jest wymagane!");
        }
        return Yup.object().nullable();
      }
    ),
    postCode: Yup.string().when(
      "sameThenCompanyAddress",
      (sameThenCompanyAddress: boolean[]) => {
        if (!sameThenCompanyAddress[0]) {
          return Yup.string()
            .matches(/^[0-9]{2}-[0-9]{3}/, "Błędy kod kreskowy! Format XX-XXX")
            .required("Pole kod pocztowy jest wymagane!");
        }
        return Yup.string();
      }
    ),
    houseNumber: Yup.string().when(
      "sameThenCompanyAddress",
      (sameThenCompanyAddress: boolean[]) => {
        if (!sameThenCompanyAddress[0]) {
          return Yup.string()
            .max(
              10,
              "Numer budynku nie może się składać z więcej niż 10 znaków!"
            )
            .required("Numer budynku jest wymagany!");
        }
        return Yup.string();
      }
    ),
    localNumber: Yup.string().when(
      "sameThenCompanyAddress",
      (sameThenCompanyAddress: boolean[]) => {
        if (!sameThenCompanyAddress[0]) {
          return Yup.string()
            .matches(
              /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
              "Numer lokalu może skłądać się z cyfr, liter i spacji."
            )
            .max(9, "Numer lokalu może mieć maksymalnie 9 znaków");
        }
        return Yup.string();
      }
    ),
  }),
  krs: Yup.string().when("companyType", (companyType: TOption[]) => {
    if (companyType[0]?.value === "CAPITAL_AND_PARTNERSHIP") {
      return Yup.string()
        .matches(/^[0-9]*$/, "KRS może zawierać tylko cyfry.")
        .required("KRS jest wymagany!");
    }
    return Yup.string();
  }),
  capital: Yup.string().when("companyType", (companyType: TOption[]) => {
    if (companyType[0]?.value === "CAPITAL_AND_PARTNERSHIP") {
      return Yup.number()
        .min(1, "Wartość musi być większa od 0.")
        .required("Kapitał zakładowy jest wymagany!");
    }
    return Yup.string();
  }),
  court: Yup.string().when("companyType", (companyType: TOption[]) => {
    if (companyType[0]?.value === "CAPITAL_AND_PARTNERSHIP") {
      return Yup.string()
        .min(3, "Pole musi się składać z minimum 3 znaków!")
        .max(255, "Pole nie może się składać z więcej niż 255 znaków!")
        .required("Pole sąd rejonowy jest wymagane!");
    }
    return Yup.string();
  }),
  courtDepartment: Yup.string().when(
    "companyType",
    (companyType: TOption[]) => {
      if (companyType[0]?.value === "CAPITAL_AND_PARTNERSHIP") {
        return Yup.string()
          .min(3, "Pole musi się składać z minimum 3 znaków!")
          .max(255, "Pole nie może się składać z więcej niż 255 znaków!")
          .required("Pole wydział sądowy jest wymagane!");
      }
      return Yup.string();
    }
  ),
  point: Yup.object().shape({
    address: Yup.object().shape({
      state: Yup.object().when(
        "sameThenCompanyAddress",
        (sameThenCompanyAddress: boolean[]) => {
          if (!sameThenCompanyAddress[0]) {
            return Yup.object().required("Pole województwo jest wymagane!");
          }
          return Yup.object().notRequired();
        }
      ),
      province: Yup.object().when(
        "sameThenCompanyAddress",
        (sameThenCompanyAddress: boolean[]) => {
          if (!sameThenCompanyAddress[0]) {
            return Yup.object().required("Pole powiat jest wymagane!");
          }
          return Yup.object().notRequired();
        }
      ),
      commune: Yup.object().when(
        "sameThenCompanyAddress",
        (sameThenCompanyAddress: boolean[]) => {
          if (!sameThenCompanyAddress[0]) {
            return Yup.object().required("Pole gmina jest wymagane!");
          }
          return Yup.object().notRequired();
        }
      ),
      city: Yup.object().when(
        "sameThenCompanyAddress",
        (sameThenCompanyAddress: boolean[]) => {
          if (!sameThenCompanyAddress[0]) {
            return Yup.object().required("Pole miasto jest wymagane!");
          }
          return Yup.object().notRequired();
        }
      ),
      postCode: Yup.string().when(
        "sameThenCompanyAddress",
        (sameThenCompanyAddress: boolean[]) => {
          if (!sameThenCompanyAddress[0]) {
            return Yup.string().required("Pole kod pocztowy jest wymagane!");
          }
          return Yup.string();
        }
      ),
      houseNumber: Yup.string().when(
        "sameThenCompanyAddress",
        (sameThenCompanyAddress: boolean[]) => {
          if (!sameThenCompanyAddress[0]) {
            return Yup.string()
              .max(
                10,
                "Numer budynku nie może się składać z więcej niż 10 znaków!"
              )
              .required("Numer budynku jest wymagany!");
          }
          return Yup.object().notRequired();
        }
      ),
      localNumber: Yup.string().when(
        "sameThenCompanyAddress",
        (sameThenCompanyAddress: boolean[]) => {
          if (!sameThenCompanyAddress[0]) {
            return Yup.string()
              .matches(
                /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/,
                "Numer lokalu może skłądać się z cyfr, liter i spacji."
              )
              .max(9, "Numer lokalu może mieć maksymalnie 9 znaków");
          }
          return Yup.string().notRequired();
        }
      ),
    }),
    // gps: Yup.string().required(REQUIRED_TEXT),
    organizationType: Yup.string().required("Typ organizacji jest wymagany!"),
    retailSpace: Yup.number()
      .min(1, "Wartość musi być większa od 0.")
      .required("Powierzchnia handlowa jest wymagana!"),
    //enterToLocal: Yup.string().required(REQUIRED_TEXT),
    peopleCount: Yup.string().required(
      "Liczba osób dedykowanych do obsługi LOTTO jest wymagana!"
    ),
    //function: Yup.string().required(REQUIRED_TEXT),
  }),
  hours: Yup.object().shape({
    mon_fry: Yup.object().shape({
      all: Yup.boolean(),
      from: Yup.string().when("all", (all: boolean[]) => {
        if (!all[0]) {
          return Yup.string().required(REQUIRED_TEXT);
        }
        return Yup.string();
      }),
      to: Yup.string().when("all", (all: boolean[]) => {
        if (!all[0]) {
          return Yup.string().required(REQUIRED_TEXT);
        }
        return Yup.string();
      }),
    }),
    monday: Yup.object().when("other", (other: boolean[]) => {
      if (other[0]) {
        return Yup.object().shape({
          all: Yup.boolean(),
          from: Yup.string().when("all", (all: boolean[]) => {
            if (!all[0]) {
              return Yup.string().required(REQUIRED_TEXT);
            }
            return Yup.string();
          }),
          to: Yup.string().when("all", (all: boolean[]) => {
            if (!all[0]) {
              return Yup.string().required(REQUIRED_TEXT);
            }
            return Yup.string();
          }),
        });
      } else {
        return Yup.object();
      }
    }),
    tuesday: Yup.object().when("other", (other: boolean[]) => {
      if (other[0]) {
        return Yup.object().shape({
          all: Yup.boolean(),
          from: Yup.string().when("all", (all: boolean[]) => {
            if (!all[0]) {
              return Yup.string().required(REQUIRED_TEXT);
            }
            return Yup.string();
          }),
          to: Yup.string().when("all", (all: boolean[]) => {
            if (!all[0]) {
              return Yup.string().required(REQUIRED_TEXT);
            }
            return Yup.string();
          }),
        });
      } else {
        return Yup.object();
      }
    }),
    wednesday: Yup.object().when("other", (other: boolean[]) => {
      if (other[0]) {
        return Yup.object().shape({
          all: Yup.boolean(),
          from: Yup.string().when("all", (all: boolean[]) => {
            if (!all[0]) {
              return Yup.string().required(REQUIRED_TEXT);
            }
            return Yup.string();
          }),
          to: Yup.string().when("all", (all: boolean[]) => {
            if (!all[0]) {
              return Yup.string().required(REQUIRED_TEXT);
            }
            return Yup.string();
          }),
        });
      } else {
        return Yup.object();
      }
    }),
    thursday: Yup.object().when("other", (other: boolean[]) => {
      if (other[0]) {
        return Yup.object().shape({
          all: Yup.boolean(),
          from: Yup.string().when("all", (all: boolean[]) => {
            if (!all[0]) {
              return Yup.string().required(REQUIRED_TEXT);
            }
            return Yup.string();
          }),
          to: Yup.string().when("all", (all: boolean[]) => {
            if (!all[0]) {
              return Yup.string().required(REQUIRED_TEXT);
            }
            return Yup.string();
          }),
        });
      } else {
        return Yup.object();
      }
    }),
    friday: Yup.object().when("other", (other: boolean[]) => {
      if (other[0]) {
        return Yup.object().shape({
          all: Yup.boolean(),
          from: Yup.string().when("all", (all: boolean[]) => {
            if (!all[0]) {
              return Yup.string().required(REQUIRED_TEXT);
            }
            return Yup.string();
          }),
          to: Yup.string().when("all", (all: boolean[]) => {
            if (!all[0]) {
              return Yup.string().required(REQUIRED_TEXT);
            }
            return Yup.string();
          }),
        });
      } else {
        return Yup.object();
      }
    }),
    saturday: Yup.object().shape({
      all: Yup.boolean(),
      from: Yup.string().when("all", (all: boolean[]) => {
        if (!all[0]) {
          return Yup.string().required(REQUIRED_TEXT);
        }
        return Yup.string();
      }),
      to: Yup.string().when("all", (all: boolean[]) => {
        if (!all[0]) {
          return Yup.string().required(REQUIRED_TEXT);
        }
        return Yup.string();
      }),
    }),
    sunday: Yup.object().shape({
      all: Yup.boolean(),
      from: Yup.string().when("all", (all: boolean[]) => {
        if (!all[0]) {
          return Yup.string().required(REQUIRED_TEXT);
        }
        return Yup.string();
      }),
      to: Yup.string().when("all", (all: boolean[]) => {
        if (!all[0]) {
          return Yup.string().required(REQUIRED_TEXT);
        }
        return Yup.string();
      }),
    }),
  }),
  approvement: Yup.object().shape({
    infoPackage: Yup.boolean().isTrue(),
    instalation: Yup.boolean().isTrue(),
    infoApproval: Yup.boolean().isTrue(),
    autorization: Yup.boolean().isTrue(),
    statments: Yup.boolean().isTrue(),
    taxStatments: Yup.boolean().isTrue(),
    game: Yup.boolean().isTrue(),
    warrnig: Yup.boolean().isTrue(),
  }),
});
