import { INPUT_TYPES } from "helper/enums";

export const REPRESENTATIVE = [
  {
    name: "firstName",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.FIRSTNAME.LABEL",
  },
  {
    name: "lastName",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.LASTNAME.LABEL",
  },
  {
    name: "phone",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.PHONE_NUMBER.LABEL",
  },
  {
    name: "email",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.EMIAL.LABEL",
  },
  {
    name: "pesel",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.PESEL.LABEL",
    conditions: [
      { toCompare: "companyType.value", acceptValue: "JDG" },
      { toCompare: "companyType.value", acceptValue: "CIVIL" },
    ],
  },
  {
    name: "identityCardNumber",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.ID_CARD_NUMBER.LABEL",
    conditions: [
      { toCompare: "companyType.value", acceptValue: "JDG" },
      { toCompare: "companyType.value", acceptValue: "CIVIL" },
    ],
  },
  {
    name: "position",
    type: INPUT_TYPES.CREATABLE_SELECT,
    placeholder: "FORM_PDF.FORM.REPRESENTATIVE.POSITION.LABEL",
    label: "FORM_PDF.FORM.REPRESENTATIVE.POSITION.LABEL",
    options: [
      { value: "Zarząd", label: "Zarząd" },
      {
        value: "Przedsiębiorca lub Wspólnik",
        label: "Przedsiębiorca lub Wspólnik",
      },
      { value: "Wspólnik spółki osobowej", label: "Wspólnik spółki osobowej" },
      { value: "Pełnomocnik", label: "Pełnomocnik" },
      { value: "Prokurent", label: "Prokurent" },
    ],
  },
  // {
  //     name: 'position',
  //     type: INPUT_TYPES.TEXT,
  //     label: "FORM_PDF.FORM.REPRESENTATIVE.POSITION.LABEL"
  // },
  {
    name: "address.state",
    data: "voivodeships",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.STATE.LABEL",
    placeholder: "FORM_PDF.FORM.REPRESENTATIVE.STATE.LABEL",
    options: [],
    clearList: [
      "address.province",
      "address.commune",
      "address.city",
      "address.street",
    ],
    //depend: true
  },
  {
    name: "address.province",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.PROVINCE.LABEL",
    placeholder: "FORM_PDF.FORM.REPRESENTATIVE.PROVINCE.LABEL",
    options: [],
    depend: true,
    url: "/api-form/teryt/counties",
    dependName: "address.state",
    apiValueName: "countyId",
    apiLabelName: "nazwa",
    clearList: ["address.commune", "address.city", "address.street"],
  },
  {
    name: "address.commune",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
    placeholder: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
    options: [],
    depend: true,
    url: "/api-form/teryt/communities",
    dependName: "address.province",
    apiValueName: "communityId",
    apiLabelName: "nazwa",
    clearList: ["address.city", "address.street"],
  },
  {
    name: "address.city",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.CITY.LABEL",
    placeholder: "FORM_PDF.FORM.REPRESENTATIVE.CITY.LABEL",
    options: [],
    depend: true,
    url: "/api-form/teryt/cities",
    dependName: "address.commune",
    apiValueName: "cityId",
    apiLabelName: "nazwa",
    clearList: ["address.street"],
  },
  {
    name: "address.postCode",
    type: INPUT_TYPES.POST_CODE,
    label: "FORM_PDF.FORM.REPRESENTATIVE.POST_CODE.LABEL",
  },
  {
    name: "address.street",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.STREET.LABEL",
    placeholder: "FORM_PDF.FORM.REPRESENTATIVE.STREET.LABEL",
    options: [],
    depend: true,
    url: "/api-form/teryt/streets",
    dependName: "address.city",
    apiValueName: "id",
    apiLabelName: "nazwA1",
  },
  {
    name: "address.houseNumber",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.HOUSE_NUMBER.LABEL",
  },
  {
    name: "address.localNumber",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.REPRESENTATIVE.LOCAL_NUMBER.LABEL",
  },
];
