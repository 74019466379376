import { divIcon } from 'leaflet';
import { useState, useCallback, useEffect } from 'react'
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker, Popup } from 'react-leaflet'
import markerIcon from 'assets/location-dot-solid.svg';
import PopupContent from './PopupContent';

const iconMarkup = renderToStaticMarkup(
    <div className='map__marker'>
        <img src={markerIcon} alt="marker" />XXX
    </div>
);
const customMarkerIcon = divIcon({
    html: iconMarkup
});

type TCustomMarker = {
    map: any
    locationName: string
    setLocationName: Function
    isLoading: boolean
    getLocationName: Function
}

const CustomMarker = (props: TCustomMarker) => {
    const { map, locationName, setLocationName, isLoading, getLocationName } = props
    const [position, setPosition] = useState(() => map.getCenter())

    const onMove = useCallback(() => {
        setPosition(map.getCenter())
        setLocationName("")
    }, [map, setLocationName])

    useEffect(() => {
        map.on('move', onMove)
        return () => {
            map.off('move', onMove)
        }
    }, [map, onMove])

    return (
        <Marker icon={customMarkerIcon} position={position}>
            <Popup>
                <PopupContent locationName={locationName} getLocationName={getLocationName} isLoading={isLoading} />
            </Popup>
        </Marker>
    )
}

export default CustomMarker