import {useState} from 'react'
import geoIcon from "assets/geo.svg"
import GeoModal, { TSingleDataForm } from "components/modals/GeoModal"
import { Field, useFormikContext } from 'formik'
import { TInputProps } from 'types/inputProps'
import { getValue } from 'helper/functions'
import { INPUT_TYPES } from 'helper/enums'

const GeoInput = (props: TInputProps) => {
    const [isGeoModalOpen, setIsGeoModalOpen] = useState(false)
    const { setFieldValue, validateForm, values } = useFormikContext();

    const saveData = (values: TSingleDataForm[]) => {
        values.forEach((item) => {
            setFieldValue(item.name, item.value) 
        })
        setTimeout(() => {
            validateForm()
        }, 1000)
    }

    const getAddress = () => {
        if (Array.isArray(props.data)){
            const data = {
                state: getValue(values, 'point.address.state'),
                county: getValue(values, 'point.address.province'),
                commune: getValue(values, 'point.address.commune'),
                city: getValue(values, "point.address.city"),
                street: getValue(values, 'point.address.street'),
                houseNumber: getValue(values, 'point.address.houseNumber'),
                postalcode: getValue(values, 'point.address.postCode')
            }
            return(data)
        } 
        return null
    }

    return (
        <div className="d-flex">
            <Field className={"form-control"} name={props.name} type={INPUT_TYPES.TEXT} hidden={false} disabled={true} />
            <div className="ms-3 p-2 d-flex align-items-center cursor-pointer" onClick={() => setIsGeoModalOpen(true)}>
                <img width={25} src={geoIcon} alt="geo" />
            </div>
            <GeoModal isOpen={isGeoModalOpen} setIsOpen={() => setIsGeoModalOpen(false)} saveData={saveData} address={getAddress()} />
        </div>
    )
}

export default GeoInput