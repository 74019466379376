
type TWhen = {
    children: JSX.Element | JSX.Element[] | string
    condition?: boolean
}

const When = (props: TWhen) => {
    const { children, condition } = props
    if (condition) {
        return (
            <>
                {children}
            </>
        )
    } else {
        return <></>
    }
}

export default When