import React, { Fragment } from "react"

type TWhen = {
    children: JSX.Element | JSX.Element[] | string
    condition?: boolean
}

const When = (props: TWhen) => {
    const { children, condition } = props
    if (condition) {
        return <Fragment>{children}</Fragment>
    } else {
        return <div></div>
    }
}

export default When