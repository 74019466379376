import error from "assets/error.svg"

type TFormCardError = {
    children: JSX.Element[] | JSX.Element
    visible: any
}

const FormCardError = (props: TFormCardError) => {
    const { children, visible } = props

    return (
        visible ?
            <div className="form-card d-flex justify-content-center mt-2">
                <div className="form-card__content form-card__content--error w-75">
                    <img width={50} src={error} alt="error" />
                    {children}
                </div>
            </div>
            : <></>
    )
}

export default FormCardError