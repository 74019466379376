import axios from "axios";
import Footer from "components/footer/Footer";
import Header from "components/header/Header";
import { REGISTRATION_FORM_CMS_API_URL } from "config/registration-form/urls";
import { useEffect, useState } from "react";

type TSLayoutProps = {
  children: JSX.Element | JSX.Element[];
};

export type Tab = {
  id: number;
  name: string;
  url: string;
  items: SecondTab[];
};

export type SecondTab = {
  id: number;
  name: string;
  href: string;
};

const TSLayout = (props: TSLayoutProps) => {
  const [tabs, setTabs] = useState<Tab[]>([]);

  // useEffect(() => {
  //   axios
  //     .get(REGISTRATION_FORM_CMS_API_URL)
  //     .then((response) => {
  //       const newTabs: Tab[] = [];
  //       response.data.layout.widgets[13].data.forEach((element: any) => {
  //         newTabs.push({
  //           id: element.id,
  //           name: element.name,
  //           url: element.href,
  //           items: element.items,
  //         });
  //       });
  //       setTabs(newTabs);
  //     });
  // }, []);

  return (
    <div>
      <Header tabs={tabs} />
      {props.children}
      <Footer tabs={tabs} />
    </div>
  );
};

export default TSLayout;
