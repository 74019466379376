import React from "react"
import {useRef} from 'react'
import { ErrorMessage, FastField, Field } from "formik"
import { getError } from "../validators"
import { Label } from "reactstrap"
import { TInputProps } from "../types/inputProps"
import MaskedInput from 'react-text-mask'

const PostCodeInput = (props: TInputProps) => {
    const { name, type, disabled, label, depend, hidden, shouldUpdate } = props

    const shoudUpdateProp = shouldUpdate ? {shouldUpdate: shouldUpdate} : {}; 

    return (
        <div className="w-100">
            <Label className="sfa-form__label">
                {label}
            </Label>
            {depend ?
                <Field
                    component={CustomPostCodeInput}
                    name={name}
                    {...shoudUpdateProp}
                    disabled={disabled}
                    type={hidden ? 'hidden' : type}
                />
                :
                <FastField
                    component={CustomPostCodeInput}
                    name={name}
                    {...shoudUpdateProp}
                    disabled={disabled}
                    type={hidden ? 'hidden' : type}
                />
            }
        </div>
    )
}

export default PostCodeInput

const CustomPostCodeInput = ({
    field,
    form: { touched, errors },
    ...props
}: any) => {
    const ref = useRef(null);
    return (
        <div className={getError(field, touched, errors) ? 'sfa-form__input--error' : ""}>
            <MaskedInput
                className="form-control"
                placeholder="XX-XXX"
                mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                ref={ref}
                {...field}
                {...props}>
            </MaskedInput>
            <div className="sfa-form__error">
                <ErrorMessage name={field.name} />
            </div>
        </div>
    )
}
