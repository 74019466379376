import { REPRESENTATIVE } from "config/registration-form/representative"
import { CustomFormInput } from "helper/form"
import { Row, TabPane } from "reactstrap"
import { useTranslation } from "react-i18next"
import { TOption } from "types/inputProps"

type TRepresentativeTabContent = {
    index: number
    voivodeships: TOption[]
}

const RepresentativeTabContent = (props: TRepresentativeTabContent) => {
    const { index, voivodeships } = props
    const { t } = useTranslation()

    return (
        <TabPane tabId={index}>
            <Row>
                {REPRESENTATIVE.map(row => {
                    let tempRow = { ...row }
                    tempRow = { ...tempRow, "name": `representatives.${index}.${tempRow.name}` }
                    tempRow = { ...tempRow, "label": t(row.label) }
                    if (tempRow.placeholder) {
                        tempRow = { ...tempRow, "placeholder": t(tempRow.placeholder) || "" }
                    }
                    tempRow = tempRow.dependName ? { ...tempRow, 'dependName': `representatives.${index}.${row.dependName}` } : tempRow
                    const tempClearList: any = []
                    tempRow.clearList?.forEach((item) => tempClearList.push(`representatives.${index}.${item}`))
                    tempRow = tempClearList.length !== 0 ? { ...tempRow, 'clearList': tempClearList } : tempRow
                    return <CustomFormInput key={row.name} {...tempRow.data === "voivodeships" ? { ...tempRow, options: voivodeships } : tempRow} />
                })}
            </Row>
        </TabPane >
    )
}

export default RepresentativeTabContent