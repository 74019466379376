import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
} from "reactstrap";
import errorIcon from "assets/error.svg";
import doc from "../../assets/doc.svg";
import activeDov from "../../assets/activeDoc.svg";
import FormButton from "components/button/FormButton";
import { useState, useEffect } from "react";
import GeoModal, { TSingleDataForm } from "components/modals/GeoModal";
import { useFormikContext } from "formik";
import { CustomFormInput } from "helper/form";
import When from "helper/When";
import { useTranslation } from "react-i18next";
import { TInputProps } from "types/inputProps";

type TFormCard = {
  children?: JSX.Element[] | JSX.Element;
  title: string;
  active: boolean;
  index: string;
  hasError: boolean;
  isTutorial?: boolean;
  inputs?: TInputProps[];
  partsInput?: {
    part1: TInputProps[];
    part2: TInputProps[];
  };
  isVisible?: boolean;
  fields?: {
    api: string;
    goal: string;
  }[];
};

const FormCard = (props: TFormCard) => {
  const {
    children,
    title,
    active,
    index,
    hasError,
    isTutorial,
    inputs,
    isVisible,
    partsInput,
  } = props;
  const [tutorialOpen, setTutorialOpen] = useState(isTutorial || false);
  const [isGeoModalOpen, setIsGeoModalOpen] = useState(false);
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();

  const getItemClassNames = () => {
    let base = "form-card__accordion_item";
    if (active) return `${base}--active`;
    if (hasError) return `${base}--error`;
    return base;
  };

  const getHeaderClassNames = () => {
    let base = "form-card__accordion_item_header";
    if (active) return `${base}--active`;
    if (hasError) return `${base}--error`;
    return base;
  };

  const generateIcon = () => {
    if (active) {
      return <img className="me-1" src={activeDov} alt="active" />;
    } else if (hasError) {
      return <img className="me-1" src={errorIcon} alt="error" />;
    } else {
      return <img className="me-1" src={doc} alt="regular" />;
    }
  };

  const saveData = (values: TSingleDataForm[]) => {
    values.forEach((item) => {
      setFieldValue(item.name, item.value);
    });
  };

  useEffect(() => {
    setTutorialOpen(isTutorial || false);
  }, [isTutorial]);

  return (
    <When condition={isVisible === undefined ? true : isVisible}>
      <AccordionItem className={getItemClassNames()}>
        <AccordionHeader targetId={index} className={getHeaderClassNames()}>
          {/* {generateIcon()} */}
          {title}
        </AccordionHeader>
        <AccordionBody accordionId={index}>
          <div
            className={
              tutorialOpen
                ? "form-card__tutorial"
                : "form-card__tutorial--invisible"
            }
          >
            <div className="form-card__tutorial-value">
              Spróbuj wskazać lokalizację na mapie za pomocą podświetlonego
              przycisku!
            </div>
            <div className="form-card__tutorial-buttons">
              <FormButton
                onClick={() => {
                  setIsGeoModalOpen(true);
                }}
                title="Wskaż na mapie"
                type="button"
              />
              <div>lub</div>
              <FormButton
                onClick={() => setTutorialOpen(false)}
                title="Wybierz ręcznie"
                type="button"
              />
              <GeoModal
                isOpen={isGeoModalOpen}
                setIsOpen={() => setIsGeoModalOpen(false)}
                saveData={saveData}
                address={null}
              />
            </div>
          </div>
          <div className="form-card">
            {/* <span className="form-card__title">{title}</span> */}
            <div className="form-card__content">
              {active ? (
                <>
                  <When condition={partsInput !== undefined}>
                    <Row>
                      <Col md={6}>
                        <Row>
                          {partsInput?.part1?.map((row, index) => {
                            let tempRow = {
                              ...row,
                              label: row.label === "" ? "" : t(row.label),
                            };
                            if (tempRow.labels) {
                              tempRow = {
                                ...tempRow,
                                labels: t(row.labels || "") || "",
                              };
                            }
                            if (tempRow.placeholder) {
                              tempRow = {
                                ...tempRow,
                                placeholder: t(tempRow.placeholder) || "",
                              };
                            }
                            return <CustomFormInput key={index} {...tempRow} />;
                          })}
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row>
                          {partsInput?.part2?.map((row, index) => {
                            let tempRow = {
                              ...row,
                              label: row.label === "" ? "" : t(row.label),
                            };
                            if (tempRow.labels) {
                              tempRow = {
                                ...tempRow,
                                labels: t(row.labels || "") || "",
                              };
                            }
                            if (tempRow.placeholder) {
                              tempRow = {
                                ...tempRow,
                                placeholder: t(tempRow.placeholder) || "",
                              };
                            }
                            return <CustomFormInput key={index} {...tempRow} />;
                          })}
                        </Row>
                      </Col>
                    </Row>
                  </When>
                  <When condition={inputs !== undefined}>
                    <Row>
                      {inputs?.map((row, index) => {
                        let tempRow = {
                          ...row,
                          label: row.label === "" ? "" : t(row.label),
                        };
                        if (tempRow.labels) {
                          tempRow = {
                            ...tempRow,
                            labels: t(row.labels || "") || "",
                          };
                        }
                        if (tempRow.placeholder) {
                          tempRow = {
                            ...tempRow,
                            placeholder: t(tempRow.placeholder) || "",
                          };
                        }
                        return <CustomFormInput key={index} {...tempRow} />;
                      })}
                    </Row>
                  </When>
                  <When condition={inputs === undefined}>
                    {children || <></>}
                  </When>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </AccordionBody>
      </AccordionItem>
    </When>
  );
};

export default FormCard;
