import axios from "axios";
import { Trans } from "react-i18next";
import { toast } from "react-toastify";

type TRequestProps = {
    url: string
    param?: any
    then: Function
    onFinnaly?: Function
    onCatch?: Function
}

export const getRequest = async (props: TRequestProps) => {
    const { url, param, then, onFinnaly, onCatch } = props
    await axios.get(url, param || {})
        .then((response) => {
            then(response.data.value)
        })
        .catch(() => {
            if (onCatch) {
                onCatch()
            } else {
                toast.error(<Trans>API_CODES.400</Trans>)
            }  
        })
        .finally(() => {
            if (onFinnaly){
                onFinnaly()
            }
        })
}