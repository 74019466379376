export enum INPUT_TYPES {
    TEXT = "text",
    NUMBER = "number",
    PESEL = "pesel",
    EMAIL = "email",
    CHECKBOX = "checkbox",
    SWITCH = "switch",
    SELECT = "select",
    DATE = "date",
    TIME = "time",
    GEO = 'geo',
    CREATABLE_SELECT = 'creatableSelect',
    TIME_RANGE = 'timeRange',
    ASYNC_SELECT = 'asyncSelect',
    NUMBER_WITH_BUTTON = 'textWithButton',
    POST_CODE = 'postCode'
}