import React from 'react'
import { Col, Label, Row } from 'reactstrap'
import { Field } from 'formik'
import { TInputProps } from '../types/inputProps'
import SelectInput from './SelectInputReturnValue'
import { getValue } from '../helper/functions'

const TimeRangeInput = (props: TInputProps) => {
  const { name, options, label, isSearchable, shouldUpdate } = props

  const shoudUpdateProp = shouldUpdate ? { shouldUpdate: shouldUpdate } : {}

  return (
    <Field
      name={name}
      isSearchable={isSearchable === undefined ? true : isSearchable}
      label={label}
      {...shoudUpdateProp}
      component={CustomTimeRangeInput}
      options={options || []}
    />
  )
}
export default TimeRangeInput

type TTimeRangeActions = 'IN_HOURS' | 'AROUND_THE_CLOCK' | 'CLOSED'

const CustomTimeRangeInput = ({
  field,
  form: { values, setFieldValue },
  ...props
}: any) => {
  const setValues = (value: TTimeRangeActions) => {
    if (value === 'CLOSED') {
      setFieldValue(`${field.name}.from`, props.options[0].value)
      setFieldValue(`${field.name}.to`, props.options[0].value)
    } else if (value === 'AROUND_THE_CLOCK') {
      setFieldValue(`${field.name}.from`, props.options[0].value)
      setFieldValue(
        `${field.name}.to`,
        props.options[props.options.length - 1].value
      )
    }
  }

  const ACTION_OPTIONS = [
    { label: 'W godzinach', value: 'IN_HOURS' },
    { label: 'Całodobowo', value: 'AROUND_THE_CLOCK' },
    { label: 'Nieczynne', value: 'CLOSED' }
  ]

  const isSelectDisabled = () => {
    const action = getValue(values, `${field.name}.action`)
    return action && (action === 'CLOSED' || action === 'AROUND_THE_CLOCK')
  }

  return (
    <div>
      <Label className='sfa-form__label'>{props.label}</Label>
      <Row>
        <Col xl={4} lg={6} sm={6} xs={6}>
          <SelectInput
            label=''
            type=''
            isSearchable={props.isSearchable}
            depend={true}
            name={`${field.name}.from`}
            placeholder='Od'
            options={props.options}
            disabled={isSelectDisabled()}
          />
        </Col>
        <Col xl={4} lg={6} sm={6} xs={6}>
          <SelectInput
            label=''
            type=''
            isSearchable={props.isSearchable}
            name={`${field.name}.to`}
            depend={true}
            placeholder='do'
            options={props.options}
            disabled={isSelectDisabled()}
          />
        </Col>
        <Col xl={4} lg={6} sm={6} xs={6}>
          <SelectInput
            onChange={(option: any) => {
              setValues(option.value)
            }}
            label=''
            type=''
            placeholder='Wybierz...'
            name={`${field.name}.action`}
            options={ACTION_OPTIONS}
          />
        </Col>
      </Row>
    </div>
  )
}
