import { INPUT_TYPES } from "helper/enums";

export const APPROVMENTS = [
    {
        name: "approvement.infoPackage",
        label: "FORM_PDF.FORM.APPROVMENTS.INFO_PACKAGE.LABEL",
        type: INPUT_TYPES.CHECKBOX,
        size: 12
    },
    {
        name: "approvement.instalation",
        label: "FORM_PDF.FORM.APPROVMENTS.INSTALATION.LABEL",
        type: INPUT_TYPES.CHECKBOX,
        size: 12 
    },
    {
        name: "approvement.infoApproval",
        label: "FORM_PDF.FORM.APPROVMENTS.INFO_APPROVAL.LABEL",
        type: INPUT_TYPES.CHECKBOX,
        size: 12
    },
    {
        name: "approvement.autorization",
        label: "FORM_PDF.FORM.APPROVMENTS.AUTORIZATION.LABEL",
        type: INPUT_TYPES.CHECKBOX,
        size: 12
    },
    {
        name: "approvement.statments",
        label: "FORM_PDF.FORM.APPROVMENTS.STATMENTS.LABEL",
        labels: "FORM_PDF.FORM.APPROVMENTS.STATMENTS.LABELS",
        type: INPUT_TYPES.CHECKBOX,
        size: 12
    },
    {
        name: "approvement.taxStatments",
        label: "FORM_PDF.FORM.APPROVMENTS.TAX_STATMENTS.LABEL",
        labels: "FORM_PDF.FORM.APPROVMENTS.TAX_STATMENTS.LABELS",
        type: INPUT_TYPES.CHECKBOX,
        size: 12,
        depend: true
    },
    {
        name: "approvement.game",
        label: "FORM_PDF.FORM.APPROVMENTS.GAME.LABEL",
        type: INPUT_TYPES.CHECKBOX,
        size: 12
    },
    {
        name: "approvement.warrnig",
        label: "FORM_PDF.FORM.APPROVMENTS.WARRNIG.LABEL",
        type: INPUT_TYPES.CHECKBOX,
        size: 12
    }
]