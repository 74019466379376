const BipIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 16"
      data-v-68dbcadd=""
      className="bip-icon"
    >
      <path
        id="bip"
        d="M5691.56,1567.83v-2.54a4.018,4.018,0,0,0,2.3.65,4.773,4.773,0,1,0-4.49-4.77v6.66a1.134,1.134,0,0,0,1.09,1.18,1.233,1.233,0,0,0,1.1-1.18m2.37-9.07a2.385,2.385,0,0,1,2.31,2.41,2.343,2.343,0,0,1-2.31,2.42,2.381,2.381,0,0,1-2.31-2.42,2.3,2.3,0,0,1,2.31-2.41m-18.24-5.68a1.134,1.134,0,0,0-1.09,1.18v6.91h0a4.535,4.535,0,1,0,2.19-4.11v-2.87a1.118,1.118,0,0,0-1.1-1.11m3.41,5.68a2.385,2.385,0,0,1,2.31,2.41,2.343,2.343,0,0,1-2.31,2.42,2.381,2.381,0,0,1-2.31-2.42,2.341,2.341,0,0,1,2.31-2.41m6.8-2.35a1.131,1.131,0,0,0-1.09,1.17v3.98c0,1.44.18,3.4,1.82,4.25a1.062,1.062,0,0,0,1.46-.52,1.158,1.158,0,0,0-.48-1.57c-0.13-.07-0.61-0.33-0.61-2.16v-3.98a1.133,1.133,0,0,0-1.1-1.17m-27.52-3.4,11.71,12.37,0.02-12.38Zm27.52,0.07a1.308,1.308,0,1,0,1.22,1.31,1.271,1.271,0,0,0-1.22-1.31"
        transform="translate(-5658.38 -1553)"
      ></path>
    </svg>
  );
};

export default BipIcon;
