import React from 'react'
import { useState } from 'react'
import { ErrorMessage, FastField, Field } from 'formik'
import { getError } from '../validators'
import { Input, Label, Spinner } from 'reactstrap'
import { TInputField, TInputProps } from '../types/inputProps'
import axios from 'axios'
import { toast } from 'react-toastify'
import When from '../When'
import Tooltip from '../Tooltip'
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

// TODO: Add captcha

const SimpleInputWithButton = (props: TInputProps) => {
  const {
    name,
    type,
    disabled,
    label,
    depend,
    hidden,
    fields,
    requestLimit,
    text,
    url,
    fieldsToTouch,
    shouldUpdate
  } = props

  const shoudUpdateProp = shouldUpdate ? { shouldUpdate: shouldUpdate } : {}

  return (
    <div className='w-100'>
      <Label className='sfa-form__label'>{label}</Label>
      {depend ? (
        <Field
          component={CustomSimpleInputWithButton}
          name={name}
          fields={fields}
          text={text}
          {...shoudUpdateProp}
          url={url}
          fieldsToTouch={fieldsToTouch}
          requestLimit={requestLimit}
          disabled={disabled}
          type={hidden ? 'hidden' : type}
        />
      ) : (
        <FastField
          component={CustomSimpleInputWithButton}
          name={name}
          fields={fields}
          url={url}
          {...shoudUpdateProp}
          text={text}
          fieldsToTouch={fieldsToTouch}
          requestLimit={requestLimit}
          disabled={disabled}
          type={hidden ? 'hidden' : type}
        />
      )}
    </div>
  )
}

export default SimpleInputWithButton

const CustomSimpleInputWithButton = ({
  field,
  form: {
    values,
    touched,
    errors,
    setFieldValue,
    validateForm,
    setFieldTouched
  },
  ...props
}: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const [counter, setCounter] = useState(0)
  // const { executeRecaptcha } = useGoogleReCaptcha();

  const setData = async () => {
    console.log(isLoading)
    setIsLoading(true)
    const type = values.companyType?.value === 'JDG' ? 'F' : 'P'
    // let token = ""
    // if (executeRecaptcha) {
    //     token = await executeRecaptcha('getCompoantData');
    // }
    // axios.get(`${props.url}${field?.value}`, { headers: { 'recaptcha': token } })
    axios
      .get(`${props.url}${field?.value}`)
      .then((res) => {
        if (res.data[0].type !== type) {
          toast.error(
            'UWAGA! Pobrane dane na podstawie numeru NIP posiadają inny typ działalności, niż podana forma działalności w formularzu. Należy ponownie wybrać formę działalności w pierwszej sekcji.'
          )
          setFieldValue('companyType', null)
        }
        const data = {
          voivodeship: res.data[0].voivodeship,
          county: res.data[0].county,
          community: res.data[0].commune,
          city: res.data[0].city
        }
        axios
          .post('/api-form/sal/verification', { ...data })
          .then((response) => {
            props.fields.forEach((item: TInputField) => {
              if (item.goal === 'registeredAddress.street') {
                setFieldValue(item.goal, {
                  label: res.data[0][item.api].replace('ul. ', ''),
                  value: res.data[0][item.api].replace('ul. ', '')
                })
              } else {
                if (item.isSelect && response.data.value[item.api]) {
                  setFieldValue(item.goal, {
                    label: response.data.value[item.api].name,
                    value: `${response.data.value[item.api].id}`
                  })
                } else {
                  setFieldValue(item.goal, res.data[0][item.api])
                }
              }
            })
            setTimeout(() => {
              validateForm()
            }, 1000)
          })
          .catch(() => {
            if (counter + 1 < 3) {
              toast.error('Nie udało się pobrać danych o firmie.')
            } else {
              toast.error(
                'Nie udało się pobrać danych o firmie na podstawie NIP, funkcjonalność została zablokowana. Wpisz dane ręcznie.'
              )
            }
          })
      })
      .catch(() => {
        setTimeout(() => {}, 1000)
        toast.error('Nie udało się pobrać danych o firmie.')
      })
      .finally(() => {
        setIsLoading(false)
        if (props.fieldsToTouch) {
          props.fieldsToTouch?.forEach((item: string) => {
            setFieldTouched(item)
          })
        }

        setCounter(counter + 1)
      })
  }

  const isButtonEnable = () => {
    return (
      errors[field.name] === undefined &&
      field.value !== '' &&
      counter < props.requestLimit
    )
  }

  const getButtonClassname = () => {
    if (isButtonEnable()) {
      return 'btn btn-primary d-flex align-items-center'
    } else {
      return 'btn btn-primary disabled d-flex align-items-center'
    }
  }

  const getTooltipTextWhenFieldValueIsCorrect = () => {
    return counter < props.requestLimit
      ? props.text.tooltipIfEnable
      : props.text.tooltipIfRequestLimit
  }

  return (
    <div
      className={
        getError(field, touched, errors) ? 'sfa-form__input--error' : ''
      }
    >
      <div className='d-flex'>
        <Input {...field} />
        <div>
          <Tooltip
            text={
              errors[field.name] === undefined && field.value !== ''
                ? getTooltipTextWhenFieldValueIsCorrect()
                : props.text.tooltipIfDisabled
            }
          >
            <div>
              <div onClick={() => setData()} className={getButtonClassname()}>
                {props.text.button}
                <When condition={isLoading}>
                  <Spinner className='ms-2' size={'sm'} />
                </When>
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className='sfa-form__error'>
        <ErrorMessage name={field.name} />
      </div>
    </div>
  )
}
