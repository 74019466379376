import React from "react"

type TTooltip = {
    children: JSX.Element
    text: string
}

const Tooltip = (props: TTooltip) => {
    const { children, text } = props
    return (
        <div className="form_tooltip">
            {children}
            <span className="form_tooltiptext">{text}</span>
        </div>
    )
}

export default Tooltip
