import React, { Fragment } from 'react'
import { ErrorMessage, FastField, Field } from 'formik'
import { getRequest } from '../helper/apiCaller'
import { Label } from 'reactstrap'
import { useEffect, useState, useCallback } from 'react'
import { getError } from '../validators'
import { TInputProps, TOption } from '../types/inputProps'
import CreatableSelect from 'react-select/creatable'

const AsyncCreatableSelectInput = (props: TInputProps) => {
  const {
    name,
    disabled,
    placeholder,
    label,
    depend,
    url,
    dependName,
    apiValueName,
    apiLabelName,
    options,
    clearList,
    shouldUpdate,
    defaultParams
  } = props

  const shoudUpdateProp = shouldUpdate ? { shouldUpdate: shouldUpdate } : {}

  if (depend) {
    return (
      <Field
        name={name}
        disabled={disabled}
        label={label}
        options={options || []}
        dependName={dependName || ''}
        apiValueName={apiValueName || ''}
        apiLabelName={apiLabelName || ''}
        defaultParams={defaultParams}
        url={url || ''}
        component={CustomCreatableAsyncSelectInput}
        clearList={clearList || []}
        {...shoudUpdateProp}
        placeholder={placeholder}
      />
    )
  } else {
    return (
      <FastField
        name={name}
        label={label}
        disabled={disabled}
        options={options || []}
        dependName={dependName || ''}
        defaultParams={defaultParams}
        apiValueName={apiValueName || ''}
        apiLabelName={apiLabelName || ''}
        clearList={clearList || []}
        url={url || ''}
        {...shoudUpdateProp}
        component={CustomCreatableAsyncSelectInput}
        placeholder={placeholder}
      />
    )
  }
}

export default AsyncCreatableSelectInput

const formatCreateLabel = (inputValue: string) => `Inna:  ${inputValue}`
const CustomCreatableAsyncSelectInput = ({
  field,
  form: { touched, errors, setFieldValue, values, setFieldTouched },
  ...props
}: any) => {
  const [options, setOptions] = useState(props.options)
  const [isLoading, setIsLoading] = useState(false)
  const [prevValue, setPrevValue] = useState<any>(null)

  const loadOptions = useCallback(
    (params?: any) => {
      setIsLoading(true)
      if (props.apiLabelName === '') {
        setOptions(props.options)
        setIsLoading(false)
      } else {
        getRequest({
          url: `${props.url}`,
          param: { ...props.defaultParams, ...params },
          then: (data: any) => {
            // if (data === undefined || data.length === 0) return
            let newOptions: any = [
              {
                label: 'Dowolny',
                value: ''
              }
            ]
            data.forEach((item: any) => {
              const alreadyIncluded = newOptions.some(
                (option: any) =>
                  (option.value || option) === item[props.apiValueName]
              )
              if (!alreadyIncluded)
                newOptions.push({
                  label: item[props.apiLabelName],
                  value: item[props.apiValueName]
                })
            })
            setOptions(newOptions)
          },
          onFinnaly: () => {
            setIsLoading(false)
          }
        })
      }
    },
    [props.url, props.apiValueName, props.apiLabelName, props.options]
  )

  const getDependValue = useCallback(
    (dependFieldName?: string) => {
      let pool: string[] = [dependFieldName || props.dependName]
      if (dependFieldName) {
        if (dependFieldName.includes('.')) pool = dependFieldName.split('.')
      } else {
        if (props.dependName.includes('.')) pool = props.dependName.split('.')
      }
      let inputValue = values
      pool.forEach((item) => {
        inputValue = inputValue?.[item]
      })
      return inputValue
    },
    [props.dependName, values]
  )

  useEffect(() => {
    let params: any = {}
    props.dependName?.forEach(
      (item: { paramName: string; dependFieldName: string }) => {
        if (item.dependFieldName.includes('.')) {
          let value = getDependValue(item.dependFieldName)
          params = { ...params, [item.paramName]: value || undefined }
        } else {
          params = {
            ...params,
            [item.paramName]: values[item.dependFieldName] || undefined
          }
        }
      }
    )
    if (JSON.stringify(params) !== JSON.stringify(prevValue)) {
      setPrevValue(params)
      loadOptions(params)
    }
    // loadOptions(params)
    // if (values[props.dependName]) {
    //     loadOptions(values[props.dependName])
    // }
    // if (!props.dependName) {
    //     loadOptions()
    // }
    // if (props.dependName.includes(".")) {
    //     let value = getDependValue()
    //     if (value && prevValue !== value.value) {
    //         setPrevValue(value.value)
    //         loadOptions(value)
    //     }
    // }
  }, [values])

  return (
    <div>
      {props.label !== '' ? (
        <Label className='sfa-form__label'>{props.label}</Label>
      ) : (
        <Fragment></Fragment>
      )}
      <CreatableSelect
        {...field}
        placeholder={props.placeholder}
        options={options}
        formatCreateLabel={formatCreateLabel}
        noOptionsMessage={({ inputValue }) =>
          !inputValue
            ? 'Brak dostępnych elementów'
            : 'Brak pasujących elementów'
        }
        onBlur={() =>
          setTimeout(() => {
            setFieldTouched(field.name), 100
          })
        }
        isLoading={isLoading}
        isDisabled={props.disabled || false}
        value={
          field.value
            ? options.find((option: TOption) => {
                console.log('Values', { field, option })
                return typeof option.label === 'number'
                  ? String(option.label) === String(field.value?.label)
                  : option.label.toLowerCase() ===
                      field.value?.label?.trim().toLowerCase()
              }) || field.value?.value
            : field.value
        }
        onChange={(option: any) => {
          props.clearList.forEach((item: string) => {
            if (item.includes('|string')) {
              setFieldValue(item.split('|')[0], '')
            } else {
              setFieldValue(item, null)
            }
          })
          console.log('onChange', { option, field })
          setFieldValue(field.name, {
            label: `${option.label}`,
            value: `${option.value}`
          })
        }}
        className={
          getError(field, touched, errors)
            ? 'react-select-container sfa-form__input--error'
            : 'react-select-container'
        }
        classNamePrefix='react-select'
      />
      <div className='sfa-form__error'>
        <ErrorMessage name={field.name} />
      </div>
    </div>
  )
}
