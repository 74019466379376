import React from "react"

type TCustomProps = {
    children: JSX.Element | JSX.Element[]
}

const Custom = (props: TCustomProps) => {
    return (
        <div>
            {props.children}
        </div>
    )
}

export default Custom
