import { INPUT_TYPES } from "helper/enums";

export const getHoursOptions = () => {
  let array = [];
  for (let i = 0; i < 49; i++) {
    array.push({
      label: `${Math.floor(i / 2)}:${i % 2 === 1 ? "30" : "00"}`,
      value: `${Math.floor(i / 2)}:${i % 2 === 1 ? "30" : "00"}`,
    });
  }
  return array;
};

export const POINT_INFO = [
  {
    name: "point.address.sameThenCompanyAddress",
    type: INPUT_TYPES.SWITCH,
    label: "FORM_PDF.FORM.POINT_INFORMATION.SAME_ADDRESS.LABEL",
    size: 12,
  },
  {
    name: "point.address.state",
    data: "voivodeships",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.STATE.LABEL",
    placeholder: "FORM_PDF.FORM.POINT_INFORMATION.STATE.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    options: [],
    clearList: [
      "point.address.province",
      "point.address.commune",
      "point.address.city",
      "point.address.street",
      "point.address.houseNumber|string",
      "point.address.localNumber|string",
      "point.gps|string",
    ],
    //depend: true
  },
  {
    name: "point.address.province",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.PROVINCE.LABEL",
    placeholder: "FORM_PDF.FORM.POINT_INFORMATION.PROVINCE.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    options: [],
    depend: true,
    url: "/api-form/teryt/counties",
    dependName: "point.address.state",
    apiValueName: "countyId",
    apiLabelName: "nazwa",
    clearList: [
      "point.address.commune",
      "point.address.city",
      "point.address.street",
      "point.address.houseNumber|string",
      "point.address.localNumber|string",
      "point.gps|string",
    ],
  },
  {
    name: "point.address.commune",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
    placeholder: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    options: [],
    depend: true,
    url: "/api-form/teryt/communities",
    dependName: "point.address.province",
    apiValueName: "communityId",
    apiLabelName: "nazwa",
    clearList: [
      "point.address.city",
      "point.address.street",
      "point.address.houseNumber|string",
      "point.address.localNumber|string",
      "point.gps|string",
    ],
  },
  {
    name: "point.address.city",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.CITY.LABEL",
    placeholder: "FORM_PDF.FORM.POINT_INFORMATION.CITY.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    options: [],
    depend: true,
    url: "/api-form/teryt/cities",
    dependName: "point.address.commune",
    apiValueName: "cityId",
    apiLabelName: "nazwa",
    clearList: [
      "point.address.street",
      "point.address.houseNumber|string",
      "point.address.localNumber|string",
      "point.gps|string",
    ],
  },
  {
    name: "point.address.postCode",
    type: INPUT_TYPES.POST_CODE,
    label: "FORM_PDF.FORM.POINT_INFORMATION.POST_CODE.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    // depend: true
  },
  {
    name: "point.address.street",
    type: INPUT_TYPES.ASYNC_SELECT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.STREET.LABEL",
    placeholder: "FORM_PDF.FORM.POINT_INFORMATION.STREET.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    options: [],
    depend: true,
    url: "/api-form/teryt/streets",
    dependName: "point.address.city",
    apiValueName: "id",
    apiLabelName: "nazwA1",
    clearList: [
      "point.address.houseNumber|string",
      "point.address.localNumber|string",
      "point.gps|string",
    ],
  },
  {
    name: "point.address.houseNumber",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.HOUSE_NUMBER.LABEL",
    clearList: ["point.address.localNumber|string", "point.gps|string"],
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    //depend: true
  },
  {
    name: "point.address.localNumber",
    type: INPUT_TYPES.TEXT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.LOCAL_NUMBER.LABEL",
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    //depend: true
  },
  {
    name: "point.gps",
    type: INPUT_TYPES.GEO,
    depend: true,
    disabled: true,
    conditions: [
      {
        toCompare: "point.address.sameThenCompanyAddress",
        acceptValue: "false",
      },
    ],
    label: "",
    data: [
      "point.address.state",
      "point.address.province",
      "point.address.commune",
      "point.address.city",
      "point.address.postCode",
      "point.address.street",
      "point.address.houseNumber",
    ],
  },
  {
    name: "point.organizationType",
    type: INPUT_TYPES.CREATABLE_SELECT,
    label: "FORM_PDF.FORM.POINT_INFORMATION.POINT_TYPE.LABEL",
    placeholder: "FORM_PDF.FORM.POINT_INFORMATION.POINT_TYPE.LABEL",
    options: [
      { label: "HANDLOWA", value: "HANDLOWA" },
      { label: "USŁUGOWA", value: "USUŁUGOWA" },
    ],
  },
  {
    name: "point.retailSpace",
    type: INPUT_TYPES.NUMBER,
    label: "FORM_PDF.FORM.POINT_INFORMATION.RETAIL_SPACE.LABEL",
  },
  {
    name: "point.gameSpace",
    type: INPUT_TYPES.SWITCH,
    label: "FORM_PDF.FORM.POINT_INFORMATION.GAME_SPACE.LABEL",
  },
  // {
  //     name: 'point.enterToLocal',
  //     type: INPUT_TYPES.CREATABLE_SELECT,
  //     label: "FORM_PDF.FORM.POINT_INFORMATION.ENTER.LABEL",
  //     placeholder: "FORM_PDF.FORM.POINT_INFORMATION.ENTER.LABEL",
  //     options: [
  //         { label: "Lokal wolnostojący bez własnego wejścia (Okienko)", value: "Lokal wolnostojący bez własnego wejścia (Okienko)" },
  //         { label: "Lokal wolnostojący z własnym wejściem z ulicy", value: "Lokal wolnostojący z własnym wejściem z ulicy" },
  //         { label: "Lokal na terenie innego obiektu posiadający własne wejście wewnątrz", value: "Lokal na terenie innego obiektu posiadający własne wejście wewnątrz" },
  //         { label: "Odrębne stoisko bez własnego wejścia, działające na obszarze innego sklepu lub obiektu, np. wyspa, okienko", value: "Odrębne stoisko bez własnego wejścia, działające na obszarze innego sklepu lub obiektu, np. wyspa, okienko" }
  //     ]
  // },
  {
    name: "point.peopleCount",
    type: INPUT_TYPES.NUMBER,
    label: "FORM_PDF.FORM.POINT_INFORMATION.PERSON.LABEL",
  },
  // {
  //     name: "point.mon_fry",
  //     type: INPUT_TYPES.TIME_RANGE,
  //     label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.MON_FRY",
  //     size: 4,
  //     options: getHoursOptions()
  // },
  // {
  //     name: "point.saturday",
  //     type: INPUT_TYPES.TIME_RANGE,
  //     label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.SATURDAY",
  //     size: 4,
  //     options: getHoursOptions()
  // },
  // {
  //     name: "point.sunday",
  //     type: INPUT_TYPES.TIME_RANGE,
  //     label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.SUNDAY",
  //     size: 4,
  //     options: getHoursOptions()
  // },
  // {
  //     name: 'point.function',
  //     type: INPUT_TYPES.CREATABLE_SELECT,
  //     label: "FORM_PDF.FORM.POINT_INFORMATION.POINT_FUNCTION.LABEL",
  //     placeholder: "FORM_PDF.FORM.POINT_INFORMATION.POINT_FUNCTION.LABEL",
  //     options: [
  //         { label: "Sklep wielkopowierzchniowy - powyżej 500 m2", value: "Sklep wielkopowierzchniowy - powyżej 500 m2" },
  //         { label: "Duży sklep / Supermarket - 200 m2 - 500 m2", value: "Duży sklep / Supermarket - 200 m2 - 500 m2" },
  //         { label: "Średni sklep - 50 - 200 m2", value: "Średni sklep - 50 - 200 m2" },
  //         { label: "Mały sklep - do 50 m2", value: "Mały sklep - do 50 m2" },
  //         { label: "Salonik prasowy / Kiosk", value: "Salonik prasowy / Kiosk" },
  //         { label: "Punkt usługowy", value: "Punkt usługowy" },
  //         { label: "Stacja benzynowa", value: "Stacja benzynowa" }
  //     ]
  // }
];

export const POINT_HOURS_INFO = {
  part1: [
    {
      name: "hours.mon_fry",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.MON_FRY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "false" }],
    },
    {
      name: "hours.other",
      size: 12,
      type: INPUT_TYPES.SWITCH,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.OTHER",
    },
    {
      name: "hours.monday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.MONDAY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "true" }],
    },
    {
      name: "hours.tuesday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.TUESDAY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "true" }],
    },
    {
      name: "hours.wednesday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.WEDNESDAY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "true" }],
    },
    {
      name: "hours.thursday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.THURSDAY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "true" }],
    },
    {
      name: "hours.friday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.FRIDAY",
      size: 12,
      options: getHoursOptions(),
      conditions: [{ toCompare: "hours.other", acceptValue: "true" }],
    },
  ],
  part2: [
    {
      name: "hours.saturday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.SATURDAY",
      size: 12,
      options: getHoursOptions(),
    },
    {
      name: "hours.sunday",
      type: INPUT_TYPES.TIME_RANGE,
      label: "FORM_PDF.FORM.POINT_INFORMATION.HOURS.SUNDAY",
      size: 12,
      options: getHoursOptions(),
    },
  ],
};
