import React, { Fragment } from 'react'
import { ErrorMessage, FastField, Field } from 'formik'
import ReactSelect from 'react-select'
import { Label } from 'reactstrap'
import { TInputProps } from '../types/inputProps'

const SelectInput = (props: TInputProps) => {
  const {
    name,
    disabled,
    options,
    placeholder,
    label,
    depend,
    isSearchable,
    onChange,
    shouldUpdate
  } = props

  const shoudUpdateProp = shouldUpdate ? { shouldUpdate: shouldUpdate } : {}

  if (depend) {
    return (
      <Field
        name={name}
        label={label}
        {...shoudUpdateProp}
        component={CustomSelectInput}
        isSearchable={isSearchable === undefined ? true : isSearchable}
        placeholder={placeholder}
        options={options || []}
        disabled={disabled}
        onChange={onChange}
      />
    )
  } else {
    return (
      <FastField
        name={name}
        label={label}
        {...shoudUpdateProp}
        isSearchable={isSearchable === undefined ? true : isSearchable}
        component={CustomSelectInput}
        placeholder={placeholder}
        options={options || []}
        disabled={disabled}
        onChange={onChange}
      />
    )
  }
}

export default SelectInput

const CustomSelectInput = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  ...props
}: any) => (
  <div>
    {props.label !== '' ? (
      <Label className='sfa-form__label'>{props.label}</Label>
    ) : (
      <Fragment></Fragment>
    )}
    <ReactSelect
      {...field}
      {...props}
      noOptionsMessage={({ inputValue }) =>
        !inputValue ? 'Brak dostępnych elementów' : 'Brak pasujących elementów'
      }
      onBlur={() =>
        setTimeout(() => {
          setFieldTouched(field.name), 100
        })
      }
      isDisabled={props.disabled}
      value={
        props.options
          ? props.options.find((option: any) => option.value === field.value)
            ? props.options.find((option: any) => option.value === field.value)
            : field.value
          : ''
      }
      onChange={(option: any) => {
        props.onChange && props.onChange(option)
        setFieldValue(field.name, option.value)
      }}
      className={
        touched[field.name] && errors[field.name]
          ? 'react-select-container sfa-form__input--error'
          : 'react-select-container'
      }
      classNamePrefix='react-select'
    />
    <div className='sfa-form__error'>
      <ErrorMessage name={field.name} />
    </div>
  </div>
)
