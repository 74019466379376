import { INPUT_TYPES } from "helper/enums"

const COMPANY_TYPES = [
    { label: "JDG (jednoosobowa działalność gospodarcza) ", value: "JDG"},
    { label: "SPÓŁKI KAPITAŁOWE I OSOBOWE (spółka z ograniczoną odpowiedzialnością i spółka akcyjna, spółka jawna, partnerska, komandytowa, komandytowo-akcyjna)", value: "CAPITAL_AND_PARTNERSHIP"},
    { label: "SPÓŁKA CYWILNA", value: "CIVIL"}
]

export const PRELIMINARY_INFO = [
    {
        name: "companyType",
        type: INPUT_TYPES.SELECT,
        label: "FORM_PDF.FORM.PRELIMINARY_INFO.COMPANY_TYPE.LABEL", 
        placeholder: "FORM_PDF.FORM.PRELIMINARY_INFO.COMPANY_TYPE.PLACEHOLDER",
        options: COMPANY_TYPES,
        size: 12
    }
]