import "./index.scss";
import "react-calendar/dist/Calendar.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider } from "react-router-dom";
import { router } from "components/PrivateRoutes";
import "./i18n";
import "leaflet/dist/leaflet.css";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  const apiUrl = process.env.REACT_APP_CAPTCHA || "";

  return (
    <GoogleReCaptchaProvider reCaptchaKey={apiUrl}>
      <div>
        <ToastContainer position="bottom-left" />
        <RouterProvider router={router()} />
      </div>
    </GoogleReCaptchaProvider>
  );
}

export default App;
