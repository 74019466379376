import { useState } from "react";
import { Trans } from "react-i18next";
import FormModal from "./FormModal";
import check from "assets/check.svg";
import axios from "axios";
import When from "helper/When";
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";
import { REGISTRATION_FORM_URL } from "config/registration-form/urls";

type SuccessModalProps = {
  applicationNumber: string;
  closeModal: Function;
};

const SuccessModal = (props: SuccessModalProps) => {
  const { applicationNumber, closeModal } = props;
  const [isLoading, setIsLoading] = useState(false);

  const getPDF = () => {
    setIsLoading(true);
    axios
      .get(`${REGISTRATION_FORM_URL}/api-form/pdf?hash=${applicationNumber}`, {
        responseType: "blob",
      })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${applicationNumber}.pdf`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => toast.error("Nie udało się pobrać pliku"))
      .finally(() => setIsLoading(false));
  };

  return (
    <FormModal
      isOpen={applicationNumber !== ""}
      isLoading={false}
      size="xl"
      setIsOpen={closeModal}
      title={<Trans>MODALS.SUCCESS.TITLE</Trans>}
      onEnter={() => {}}
    >
      <div className="success-modal">
        <div className="success-modal__number">
          <div className="success-modal__number-icon">
            <img src={check} alt="success" />
          </div>
          <div>
            <div>
              <Trans>MODALS.SUCCESS.NUMBER_1</Trans>
            </div>
            <div>
              <Trans>MODALS.SUCCESS.NUMBER_2</Trans>{" "}
              <strong>#{applicationNumber}</strong>
            </div>
          </div>
        </div>
        <div className="success-modal__pdf">
          <div className="success-modal__pdf-title">
            <Trans>MODALS.SUCCESS.PDF.TITLE</Trans>
          </div>
          <div className="success-modal__pdf-link" onClick={() => getPDF()}>
            <Trans>MODALS.SUCCESS.PDF.FILE</Trans>
          </div>
          <When condition={isLoading}>
            <div className="success-modal__pdf-loading">
              Trwa pobieranie pliku...
              <Spinner />
            </div>
          </When>
          <div className="success-modal__pdf-footer">
            <Trans>MODALS.SUCCESS.PDF.FOOTER</Trans>
          </div>
        </div>
        <div className="success-modal__thanks">
          <Trans>MODALS.SUCCESS.THANKS</Trans>
        </div>
        <div className="success-modal__good-day">
          <Trans>MODALS.SUCCESS.GOOD_DAY</Trans>
        </div>
      </div>
    </FormModal>
  );
};

export default SuccessModal;
