import { Tooltip } from "@mui/material"
import FormButton from "components/button/FormButton"
import { RepresentativeObject } from "config/registration-form/initial/initial"
import { FieldArrayRenderProps } from "formik"
import When from "helper/When"
import { useTranslation } from "react-i18next"

type TAddButton = {
    arrayHelpers: FieldArrayRenderProps
    inVisible?: boolean
    setActiveTab: Function
    length: number
}

const AddButton = (props: TAddButton) => {
    const { arrayHelpers, inVisible, setActiveTab, length } = props
    const { t } = useTranslation()

    return (
        <When condition={!inVisible}>
            <Tooltip title={t("FORM_PDF.FORM.REPRESENTATIVE.ADD.TOOLTIP")}>
                <div className="add-button">
                    <FormButton
                        type='button'
                        onClick={() => {
                            arrayHelpers.push(RepresentativeObject)
                            setActiveTab(length)
                        }}
                        title={t("FORM_PDF.FORM.REPRESENTATIVE.ADD.BUTTON")} />
                </div>
            </Tooltip>
        </When>
    )
}

export default AddButton