import { useEffect, useState } from "react";
import mainLogo from "./mainLogo.svg";
import { Link } from "react-router-dom";
import When from "helper/When";
import AltLogo from "./AltLogo";
import SearchIcon from "./SearchIcon";
import BipIcon from "./BipIcon";
import { Tab } from "layouts/TSLayout";
import { MAIN_TS_URL } from "config/registration-form/urls";

type HeaderProps = {
  tabs: Tab[];
};

const Header = (props: HeaderProps) => {
  const { tabs } = props;
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(0);
  const [scrollTop, setScrollTop] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const searchHandler = () => {
    if (isSearchOpen) {
      window.location.href = `${MAIN_TS_URL}/wyszukiwarka?searchQuery=${searchValue}`;
    } else {
      setIsSearchOpen(true);
    }
  };

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollTop(e.target.documentElement.scrollTop > 0);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const handleClickMenuItem = (tab: Tab) => {
    if (tab.items.length > 0) {
      setIsMenuOpen(tab.id);
    } else {
      window.location.href = `${MAIN_TS_URL}${tab.url}`;
    }
  };

  return (
    <>
      <div
        className={`header-container ${isMenuOpen ? "menuActive" : ""} ${
          scrollTop ? "isSticky" : ""
        }`}
      >
        <div className="header">
          <a href={`${MAIN_TS_URL}/`} className="totalizator-logo-container">
            <img src={mainLogo} alt="logo" className="totalizator-logo-main" />
            <AltLogo />
          </a>
          <section
            id="navigation-container"
            className={isSearchOpen ? "search-input-open" : ""}
          >
            <div className="header-menu-wrapper">
              <ul className="menu-first-level">
                {tabs.map((tab) => (
                  <li key={tab.id}>
                    <button
                      onClick={() => handleClickMenuItem(tab)}
                      type="button"
                      className={`btn btn-menu ${
                        isMenuOpen === tab.id ? "nuxt-link-active" : ""
                      }`}
                    >
                      {tab.name}
                    </button>
                    <When condition={isMenuOpen !== 0}>
                      <div className="menu-second-level-wrapper">
                        <ul>
                          {tabs
                            .filter((item) => item.id === isMenuOpen)[0]
                            ?.items.map((secondTab) => (
                              <li key={secondTab.id}>
                                <a
                                  className="btn btn-menu"
                                  href={`${MAIN_TS_URL}${secondTab.href}`}
                                >
                                  {secondTab.name}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </div>
                    </When>
                  </li>
                ))}
              </ul>
            </div>
            <div
              onClick={() => setIsMenuOpen(0)}
              className={`background-overlay ${
                isMenuOpen !== 0 ? "active" : ""
              }`}
            ></div>
            <div
              className={`menuBackground ${isMenuOpen !== 0 ? "active" : ""}`}
            ></div>
          </section>
          <div className="search-container">
            <div
              className={`search-input-container ${
                scrollTop ? "scrolled" : ""
              }`}
            >
              <div
                className={`mobile-search mobile ${
                  isSearchOpen ? "mobile-open" : ""
                } ${scrollTop ? "scrolled" : ""}`}
              ></div>
              <input
                placeholder="Wyszukaj"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                className={`search-input mobile ${
                  isSearchOpen ? "mobile-open" : ""
                } ${scrollTop ? "scrolled" : ""}`}
              />
              {/* <button
                className={`btn-icon mobile ${
                  isSearchOpen ? "mobile-open" : ""
                } ${scrollTop ? "scrolled" : ""}`}
                onClick={() => searchHandler()}
              >
                <SearchIcon />
              </button> */}
            </div>
            <Link to="https://www.bip.totalizator.pl/">
              <BipIcon />
            </Link>
          </div>
          <div className={`hamburger ${isMobileMenuOpen ? "active" : ""}`}>
            <button
              className="hamburger-toggler"
              onClick={() => {
                setIsMobileMenuOpen(!isMobileMenuOpen);
                setIsMenuOpen(0);
              }}
            >
              <span />
              <span />
              <span />
            </button>
            <div className="hamburger-wrapper">
              <a href="/">
                <AltLogo />
              </a>
              <div
                className={`menu-body ${
                  isMenuOpen !== 0 ? "second-menu-active" : ""
                } `}
              >
                {tabs.map((tab) => (
                  <div key={tab.id}>
                    <button
                      type="button"
                      className="btn btn-menu text-white"
                      onClick={() => setIsMenuOpen(tab.id)}
                    >
                      {tab.name}
                    </button>
                  </div>
                ))}
                <div>
                  <div className="second-menu-body active">
                    <div className="second-menu-button">
                      <button
                        type="button"
                        className={`btn btn-menu btn-back`}
                        onClick={() => {
                          setIsMenuOpen(0);
                        }}
                      >
                        <svg
                          data-v-4b3868f0=""
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="15.97"
                          viewBox="0 0 24 15.97"
                        >
                          <path
                            id="back"
                            d="M5510.59,1567.03l7.84-7.01,1.41,1.34-6.43,5.64H5534v2h-20.59l6.46,5.65-1.41,1.35-7.87-7.06-0.58-.6v-0.73Z"
                            transform="translate(-5510 -1560.03)"
                          ></path>
                        </svg>
                        {tabs.filter((item) => item.id === isMenuOpen)[0]?.name}
                      </button>
                    </div>
                    <ul className="menu-content">
                      {tabs
                        .filter((item) => item.id === isMenuOpen)[0]
                        ?.items.map((secondTab) => (
                          <li key={secondTab.id}>
                            <a
                              className="btn btn-menu text-white"
                              href={`${MAIN_TS_URL}${secondTab.href}`}
                            >
                              {secondTab.name}
                            </a>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-banner-wrapper">
        <div className="hero-banner">
          <img
            className="bg-picture"
            loading="lazy"
            src="https://totalizator.pl/storage/files/2022/5/17/5fc629ac-a048-4615-ad9b-c3cbd2bba237/zalozycKolekture_ts.jpg?p="
            alt="zalozycKolekture_ts.jpg"
          ></img>
          <div className="hero-shadow"></div>
          <div
            style={{
              position: "inherit",
              zIndex: 3,
              paddingLeft: "32px",
              paddingRight: "32px",
            }}
          >
            <div className="totalizator-banner-header">
              <div className="left-side">
                <a href={`${MAIN_TS_URL}/`} className="btn-back">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="15.97"
                    viewBox="0 0 24 15.97"
                  >
                    <path
                      id="back"
                      d="M5510.59,1567.03l7.84-7.01,1.41,1.34-6.43,5.64H5534v2h-20.59l6.46,5.65-1.41,1.35-7.87-7.06-0.58-.6v-0.73Z"
                      transform="translate(-5510 -1560.03)"
                    ></path>
                  </svg>
                  Wróć do strony głównej
                </a>
                <div className="title-wrapper">
                  <div>
                    <h1>Jak założyć kolekturę</h1>
                  </div>
                  <div className="left-side-content wysiwyg text-white"></div>
                </div>
              </div>
              <div className="right-side">
                <div className="wysiwyg text-white">
                  <p>
                    Spółka dysponuje siecią niemal 20 000 punktów sprzedaży
                    umiejscowionych na terenie kraju, tworząc tym samym
                    największą sieć sprzedażową w Polsce, działającą w czasie
                    rzeczywistym (system online).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
