import React from "react"
import { ErrorMessage, FastField, Field } from "formik"
import { Input, Label } from "reactstrap"
import { getError } from "../validators"
import { TInputProps } from "../types/inputProps"
import When from "../When"
import Tooltip from "../Tooltip"

const SimpleInput = (props: TInputProps) => {
    const { name, type, disabled, label, depend, hidden, clearList, shouldUpdate, tooltipText } = props

    const shoudUpdateProp = shouldUpdate ? { shouldUpdate: shouldUpdate } : {};

    return (
        <div className="w-100">
            <Label className="sfa-form__label">
                {label}
                <When condition={tooltipText !== undefined}>
                    <Tooltip text={tooltipText || ""}>
                        <div className="form-tootltip">i</div>
                    </Tooltip>
                </When>
            </Label>
            {depend ?
                <Field
                    component={CustomSimpleInput}
                    name={name}
                    {...shoudUpdateProp}
                    clearList={clearList || []}
                    disabled={disabled}
                    type={hidden ? 'hidden' : type}
                />
                :
                <FastField
                    component={CustomSimpleInput}
                    name={name}
                    {...shoudUpdateProp}
                    clearList={clearList || []}
                    disabled={disabled}
                    type={hidden ? 'hidden' : type}
                />
            }
        </div>
    )
}

export default SimpleInput

const CustomSimpleInput = ({
    field,
    form: { touched, errors, setFieldValue },
    ...props
}: any) => {
    return (
        <div className={getError(field, touched, errors) ? 'sfa-form__input--error' : ""}>
            <Input
                {...field}
                type={props.type}
                disabled={props.disabled}
                onChange={(event: any) => {
                    props.clearList.forEach((item: string) => {
                        if (item.includes("|string")) {
                            setFieldValue(item.split("|")[0], "")
                        } else {
                            setFieldValue(item, null)
                        }
                    })
                    setFieldValue(field.name, event.target.value || "")
                }}
            />
            <div className="sfa-form__error">
                <ErrorMessage name={field.name} />
            </div>
        </div>
    )
}