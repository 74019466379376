export function isValidNip(nip: string) {
  var nipWithoutDashes = nip.replace(/-/g, "");
  var reg = /^[0-9]{10}$/;
  if (reg.test(nipWithoutDashes) === false) {
    return false;
  } else {
    var digits = ("" + nipWithoutDashes).split("");
    var checksum =
      (6 * parseInt(digits[0]) +
        5 * parseInt(digits[1]) +
        7 * parseInt(digits[2]) +
        2 * parseInt(digits[3]) +
        3 * parseInt(digits[4]) +
        4 * parseInt(digits[5]) +
        5 * parseInt(digits[6]) +
        6 * parseInt(digits[7]) +
        7 * parseInt(digits[8])) %
      11;
    return parseInt(digits[9]) === checksum;
  }
}

export function isValidRegon(regon: string) {
  var reg = /^[0-9]{9}$/;
  if (!reg.test(regon)) return false;
  else {
    var digits = ("" + regon).split("");
    var checksum =
      (8 * parseInt(digits[0]) +
        9 * parseInt(digits[1]) +
        2 * parseInt(digits[2]) +
        3 * parseInt(digits[3]) +
        4 * parseInt(digits[4]) +
        5 * parseInt(digits[5]) +
        6 * parseInt(digits[6]) +
        7 * parseInt(digits[7])) %
      11;
    if (checksum === 10) checksum = 0;

    return parseInt(digits[8]) === checksum;
  }
}

export function isValidPesel(pesel: string) {
  if (pesel.length !== 11) return false;
  let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  let controlNumber = parseInt(pesel.substring(10, 11));

  for (let i = 0; i < weight.length; i++) {
    sum += parseInt(pesel.substring(i, i + 1)) * weight[i];
  }
  sum = sum % 10;
  return (10 - sum) % 10 === controlNumber;
}

export const isValidIdentityCardNumber = (number: string) => {
  //Check length
  if (number == null || number.length !== 9) return false;

  number = number.toUpperCase();
  const letterValues = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  function getLetterValue(letter: string) {
    for (let j = 0; j < letterValues.length; j++)
      if (letter === letterValues[j]) return j;
    return -1;
  }

  //Check seria
  for (let i = 0; i < 3; i++) if (getLetterValue(number[i]) < 10) return false;
  //Check number
  for (let i = 3; i < 9; i++)
    if (getLetterValue(number[i]) < 0 || getLetterValue(number[i]) > 9)
      return false;

  //sprawdz cyfre controlna
  let sum =
    7 * getLetterValue(number[0]) +
    3 * getLetterValue(number[1]) +
    1 * getLetterValue(number[2]) +
    7 * getLetterValue(number[4]) +
    3 * getLetterValue(number[5]) +
    1 * getLetterValue(number[6]) +
    7 * getLetterValue(number[7]) +
    3 * getLetterValue(number[8]);
  sum %= 10;
  if (sum !== getLetterValue(number[3])) return false;

  return true;
};

export const getError = (field: any, touched: any, errors: any) => {
  let pool: string[] = [field.name];
  if (field.name.includes(".")) pool = field.name.split(".");
  let tempTouched = touched;
  let tempErrors = errors;
  pool.forEach((item) => {
    tempTouched = tempTouched?.[item];
    tempErrors = tempErrors?.[item];
  });
  return tempErrors && tempTouched;
};
