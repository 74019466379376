import axios from "axios";
import { useCallback, useEffect } from "react";
import { Row } from "reactstrap";
import FormButton from "components/button/FormButton";
import { INPUT_TYPES } from "helper/enums";
import { URLS } from "config/registration-form/urls";
import { CustomFormInput } from "helper/form";
import { useFormikContext } from "formik";
import { TRegistrationForm } from "components/forms/registration_form/types";
import { TFormContent } from "../GeoModal";
import { TOption } from "types/inputProps";
import { useTranslation } from "react-i18next";

type TDisplayPosition = {
  map: any;
  setLocationName: Function;
  saveData: Function;
  getLocationName: Function;
  address: {
    state: TOption;
    county: TOption;
    commune: TOption;
    city: TOption;
    street: TOption;
    houseNumber: string;
    postalcode: string;
  } | null;
  isLoading: boolean;
  formConten?: TFormContent;
  setCenter: Function;
};

const DisplayPosition = (props: TDisplayPosition) => {
  const {
    map,
    setLocationName,
    saveData,
    getLocationName,
    formConten,
    setCenter,
    isLoading,
    address,
  } = props;
  const { values, setFieldValue } = useFormikContext<TRegistrationForm>();
  const { t } = useTranslation();

  useEffect(() => {
    getLocation();
  }, [values]);

  const setData = useCallback(
    (value: string, lat: number, lon: number) => {
      map.setView([lat, lon], map.zoom);
      map.closePopup();
      setLocationName(value);
    },
    [setLocationName, map]
  );

  const getLocation = () => {
    const state = values.geo?.state?.label || "";
    const province = values.geo?.province?.label || "";
    const commune = values.geo?.commune?.label || "";
    const city = values.geo?.city?.label || "";
    const street = values.geo?.street?.label || "";
    const houseNumber = values.geo?.houseNumber || "";
    const searchString = `${state},${province},${commune},${city},${street},${houseNumber}`;

    axios
      .get(
        `https://nominatim.openstreetmap.org/search?q=${searchString}&format=json`
      )
      .then((response) => {
        if (response.data[0]) {
          setData(
            response.data[0]?.display_name,
            response.data[0]?.lat,
            response.data[0]?.lon
          );
          setCenter([response.data[0]?.lat, response.data[0]?.lon]);
          if (street !== "") {
            map.setZoom(18);
          } else if (city !== "") {
            map.setZoom(15);
          } else if (commune !== "") {
            map.setZoom(13);
          } else if (province !== "") {
            map.setZoom(11);
          } else {
            map.setZoom(9);
          }
        }
      });
  };

  useEffect(() => {
    setFieldValue("geo.state", address?.state);
    setFieldValue("geo.province", address?.county);
    setFieldValue("geo.commune", address?.commune);
    setFieldValue("geo.city", address?.city);
    setFieldValue("geo.street", address?.street);
    setFieldValue("geo.houseNumber", address?.houseNumber);
  }, []);

  useEffect(() => {
    if (formConten) {
      setFieldValue("geo.state", formConten.state);
      setFieldValue("geo.province", formConten.province);
      setFieldValue("geo.commune", formConten.commune);
      setFieldValue("geo.city", formConten.city);
      setFieldValue("geo.street", formConten.street);
    }
  }, [formConten]);

  const FORM = [
    {
      name: "geo.state",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.STATE.LABEL",
      options: [],
      depend: true,
      url: URLS.voivodeships,
      apiValueName: "voivodeshipId",
      apiLabelName: "nazwa",
      clearList: ["geo.province", "geo.commune", "geo.city", "geo.street"],
      size: 4,
    },
    {
      name: "geo.province",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.PROVINCE.LABEL",
      options: [],
      depend: true,
      url: URLS.counties,
      dependName: "geo.state",
      apiValueName: "countyId",
      apiLabelName: "nazwa",
      clearList: ["geo.commune", "geo.city", "geo.street"],
      size: 4,
    },
    {
      name: "geo.commune",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.COMMUNITY.LABEL",
      options: [],
      depend: true,
      url: URLS.communities,
      dependName: "geo.province",
      apiValueName: "communityId",
      apiLabelName: "nazwa",
      clearList: ["geo.city", "geo.street"],
      size: 4,
    },
    {
      name: "geo.city",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.CITY.LABEL",
      options: [],
      depend: true,
      url: URLS.cities,
      dependName: "geo.commune",
      apiValueName: "cityId",
      apiLabelName: "nazwa",
      clearList: ["geo.street"],
      size: 4,
    },
    {
      name: "geo.street",
      type: INPUT_TYPES.ASYNC_SELECT,
      label: "",
      placeholder: "FORM_PDF.FORM.COMPANY_INFO.STREET.LABEL",
      options: [],
      size: 4,
      depend: true,
      url: URLS.streets,
      dependName: "geo.city",
      apiValueName: "id",
      apiLabelName: "nazwA1",
    },
  ];

  return (
    <div>
      <Row className="mt-3">
        {FORM.map((item) => {
          const tempItem = { ...item, placeholder: t(item.placeholder) };
          return <CustomFormInput key={item.name} {...tempItem} />;
        })}
        <input type="text" hidden name="geo.houseNumber" />
      </Row>
      <Row>
        <div className="d-flex justify-content-between align-items-center mt-3">
          <span>
            Korzystając z opcji zoom i przenoszenia pinezki zaznacz lokalizację
            lokalu.
          </span>
          <FormButton
            type="button"
            isLoading={isLoading}
            onClick={async () => {
              saveData(await getLocationName());
            }}
            title="Zapisz"
          />
        </div>
      </Row>
    </div>
  );
};

export default DisplayPosition;
