import React from 'react'
import {useState} from 'react'
import geoIcon from "./geo.svg"
// import { TSingleDataForm } from "../modals/GeoModal"
import SimpleInput from './SimpleInput'
// import { useFormikContext } from 'formik'
import { TInputProps } from '../types/inputProps'
// import { getValue } from '../helper/functions'

const GeoInput = (props: TInputProps) => {
    const [isGeoModalOpen, setIsGeoModalOpen] = useState(false)
    console.log(isGeoModalOpen)
    // const { setFieldValue, validateForm, values } = useFormikContext();

    // const saveData = (values: TSingleDataForm[]) => {
    //     console.log(values)
    //     values.forEach((item) => {
    //         setFieldValue(item.name, item.value) 
    //     })
    //     setTimeout(() => {
    //         validateForm()
    //     }, 1000)
    // }

    // const getAddress = () => {
    //     if (Array.isArray(props.data)){
    //         const data = {
    //             state: getValue(values, 'point.address.state'),
    //             county: getValue(values, 'point.address.province'),
    //             commune: getValue(values, 'point.address.commune'),
    //             city: getValue(values, "point.address.city"),
    //             street: getValue(values, 'point.address.street'),
    //             houseNumber: getValue(values, 'point.address.houseNumber'),
    //             postalcode: getValue(values, 'point.address.postCode')
    //         }
    //         return(data)
    //     } 
    //     return null
    // }

    return (
        <div className="d-flex">
            <SimpleInput hidden={false} disabled={props.disabled} label={props.label} name={props.name} type="text"/>
            <div className="ms-3 p-2 d-flex align-items-center cursor-pointer" onClick={() => setIsGeoModalOpen(true)}>
                <img width={25} src={geoIcon} alt="geo" />
            </div>
            {/* <GeoModal isOpen={isGeoModalOpen} setIsOpen={() => setIsGeoModalOpen(false)} saveData={saveData} address={getAddress()} /> */}
        </div>
    )
}

export default GeoInput