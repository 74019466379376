import When from "helper/When"
import { Button, Spinner } from "reactstrap"

type TFormButton = {
    title: string
    type?: "submit" | "reset" | "button" | undefined
    disabled?: boolean | undefined
    color?: string
    outline?: boolean
    onClick?: Function
    buttonProps?: any
    isLoading?: boolean
}

const FormButton = (props: TFormButton) => {
    const { title, type, disabled, onClick, buttonProps, color, outline, isLoading } = props

    return (
        type !== "submit" ?
            <Button
                color={color || "primary"}
                {...buttonProps}
                disabled={disabled || isLoading}
                outline={outline}
                type={type}
                className="form-button"
                onClick={onClick ? () => onClick() : () => { }}
            >
                {title}
                <When condition={isLoading}>
                    <Spinner className="ms-2" size={"sm"} />
                </When>
            </Button>
            :
            <Button
                color={color || "primary"}
                {...buttonProps}
                disabled={disabled || isLoading}
                outline={outline}
                type={type}
                className="form-button"
            >
                {title}
                <When condition={isLoading}>
                    <Spinner className="ms-2" size={"sm"} />
                </When>
            </Button>
    )
}
export default FormButton