import { createBrowserRouter } from "react-router-dom";
import RegistrationForm from "pages/RegistrationForm";

export const router = () =>
  createBrowserRouter([
    {
      path: "/",
      element: <RegistrationForm />,
    },
    {
      path: "/formularz-zgloszeniowy",
      element: <RegistrationForm />,
    },
  ]);
