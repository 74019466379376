import { useState } from "react";
import { Trans } from "react-i18next";
import FormModal from "./FormModal";
import { MapContainer } from "react-leaflet";
import { TileLayer } from "react-leaflet";
import CustomMarker from "./geo-modal/CustomMarker";
import DisplayPosition from "./geo-modal/DisplayPosition";
import axios from "axios";
import { getRequest } from "helper/apiCaller";
import { URLS } from "config/registration-form/urls";
import { TOption } from "types/inputProps";
import { toast } from "react-toastify";

type TGeoModal = {
  isOpen: boolean;
  setIsOpen: Function;
  saveData: Function;
  address: {
    state: TOption;
    county: TOption;
    commune: TOption;
    city: TOption;
    street: TOption;
    houseNumber: string;
    postalcode: string;
  } | null;
};

export type TSingleDataForm = {
  name: string;
  value: string | { label: string; value: string };
};

export type TFormContent = {
  state: TOption | null | string;
  province: TOption | null | string;
  commune: TOption | null | string;
  city: TOption | null | string;
  street: TOption | null | string;
};

const zoom: any = 13;

const GeoModal = (props: TGeoModal) => {
  const { isOpen, setIsOpen, saveData, address } = props;
  const [map, setMap] = useState<any>(null);
  const [locationName, setLocationName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formConten, setFormContent] = useState<TFormContent | undefined>(
    undefined
  );
  const [center, setCenter] = useState<any>([52.2479, 21.0185]);

  const getLocationName = async () => {
    const cordinantes = map.getCenter();
    setIsLoading(true);
    let voivodeshipId = 0;
    let countyId = 0;
    let communityId = 0;
    let cityId = 0;
    const array: TSingleDataForm[] = [];
    await axios
      .get(
        `https://nominatim.openstreetmap.org/reverse?lat=${cordinantes.lat}&lon=${cordinantes.lng}&accept-language=pl&format=json`
      )
      .then(async (response) => {
        setLocationName(response.data.display_name);
        if (response.data) {
          if (response.data.address.state) {
            await getRequest({
              url: `${URLS.voivodeship}${response.data.address.state
                .replace("województwo", "")
                .trim()}`,
              then: (item: any) => {
                voivodeshipId = item?.voivodeshipId;
                array.push({
                  name: "point.address.state",
                  value: {
                    label: item?.nazwa,
                    value: `${item?.voivodeshipId}`,
                  },
                });
              },
            });
          }

          const searchValueCounty =
            response.data.address.county?.replace("powiat", "").trim() ||
            response.data.address.city;
          await getRequest({
            url: `${URLS.counties}/${voivodeshipId}`,
            then: (data: any) => {
              const county = data.find(
                (item: any) => item.nazwa === searchValueCounty
              );
              countyId = county?.countyId;
              array.push({
                name: "point.address.province",
                value: {
                  label: county?.nazwa,
                  value: `${county?.countyId}`,
                },
              });
            },
          });

          let searchValueMunicipality = "";
          if (response.data.address.municipality) {
            searchValueMunicipality = response.data.address.municipality
              ?.replace("gmina", "")
              .trim();
            // }else if (response.data.address.county === "Wrocław"){
            //     searchValueMunicipality = "Wrocław"
            // } else if(response.data.address.quarter){
            //     searchValueMunicipality = response.data.address.suburb?.replace("Osiedle", "").trim()
          } else if (response.data.address.city) {
            searchValueMunicipality = response.data.address.city;
          } else if (response.data.address.town) {
            searchValueMunicipality = response.data.address.town;
          } else if (response.data.address.county) {
            searchValueMunicipality = response.data.address.county;
          } else {
            searchValueMunicipality = response.data.address.suburb;
          }
          await getRequest({
            url: `${URLS.communities}/${countyId}`,
            then: (data: any) => {
              let community = data.find((item: any) =>
                searchValueMunicipality.includes(item.nazwa)
              );
              if (!community) {
                searchValueMunicipality = response.data.address.suburb;
                community = data.find((item: any) =>
                  item.nazwa.includes(searchValueMunicipality)
                );
              }
              communityId = community.communityId;
              array.push({
                name: "point.address.commune",
                value: {
                  label: community.nazwa,
                  value: `${community.communityId}`,
                },
              });
            },
            onCatch: () => {},
          });

          const searchValueCity =
            response.data.address.city ||
            response.data.address.town ||
            response.data.address.village ||
            response.data.address.county;
          await getRequest({
            url: `${URLS.cities}/${communityId}`,
            then: (data: any) => {
              const city = data.find((item: any) =>
                searchValueCity.includes(item.nazwa)
              );
              cityId = city.cityId;
              array.push({
                name: "point.address.city",
                value: {
                  label: city.nazwa,
                  value: `${city.cityId}`,
                },
              });
            },
            onCatch: () => {},
          });

          const searchValueStreet =
            response.data.address.road || response.data.address.street;
          await getRequest({
            url: `${URLS.streets}/${cityId}`,
            then: (data: any) => {
              const street = data.find((item: any) =>
                searchValueStreet.includes(item.nazwA1)
              );
              array.push({
                name: "point.address.street",
                value: {
                  label: street.nazwA1,
                  value: `${street.id}`,
                },
              });
            },
            onCatch: () => {
              toast.error(
                "Wystąpił problem z pobraniem lokalizacji, spróbuj ponownie lub wybierz ręcznie."
              );
            },
          });

          array.push({
            name: "point.address.postCode",
            value: response.data.address.postcode,
          });
          array.push({
            name: "point.address.houseNumber",
            value: response.data.address.house_number,
          });
          array.push({
            name: "point.gps",
            value: `${response.data.lat}, ${response.data.lon}`,
          });
          setFormContent({
            state:
              array.find((item) => item.name === "point.address.state")
                ?.value || null,
            province:
              array.find((item) => item.name === "point.address.province")
                ?.value || null,
            commune:
              array.find((item) => item.name === "point.address.commune")
                ?.value || null,
            city:
              array.find((item) => item.name === "point.address.city")?.value ||
              null,
            street:
              array.find((item) => item.name === "point.address.street")
                ?.value || null,

            // region: response.data.address.state,
            // city: response.data.address.city || response.data.address.town || response.data.address.village || response.data.address.county,
            // street: `${response.data.address.road} ${response.data.address.house_number || ''}`
          });
          setCenter([cordinantes.lat, cordinantes.lng]);
        }
      })
      .finally(() => setIsLoading(false));
    return array;
  };

  const displayMap = () => {
    return (
      <MapContainer center={center} zoom={zoom} ref={setMap}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {map ? (
          <CustomMarker
            map={map}
            locationName={locationName}
            setLocationName={setLocationName}
            getLocationName={getLocationName}
            isLoading={isLoading}
          />
        ) : null}
      </MapContainer>
    );
  };

  return (
    <FormModal
      isOpen={isOpen}
      isLoading={false}
      setIsOpen={setIsOpen}
      size="xl"
      title={<Trans>MODALS.GEO.TITLE</Trans>}
      onEnter={() => {}}
    >
      <div>
        {displayMap()}
        {map ? (
          <DisplayPosition
            map={map}
            address={address}
            isLoading={isLoading}
            setCenter={setCenter}
            formConten={formConten}
            saveData={(array: TSingleDataForm[]) => {
              saveData(array);
              setIsOpen(false);
            }}
            getLocationName={getLocationName}
            setLocationName={setLocationName}
          />
        ) : null}
      </div>
    </FormModal>
  );
};

export default GeoModal;
