import { faCircleArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spinner } from 'reactstrap'

type TPopupContent = {
    locationName: string
    getLocationName: Function
    isLoading: boolean
}

const PopupContent = (props: TPopupContent) => {
    const { locationName, getLocationName, isLoading } = props

    return (
        <div>
            {
                locationName === ""
                    ?
                    <div className='d-flex align-items-center justify-content-center cursor-pointer' onClick={isLoading ? () => {} : () => getLocationName()}>
                        <FontAwesomeIcon size='xl' icon={faCircleArrowDown} />
                        <div className='ms-2 d-flex align-items-center justify-content-center' style={{ whiteSpace: "nowrap" }} >
                            Pobierz lokalizację
                        </div>
                        {isLoading ? <div><Spinner /></div> : <></>}
                    </div>
                    :
                    locationName
            }
        </div>
    )
}

export default PopupContent