import { FieldArrayRenderProps } from "formik"
import { useTranslation } from "react-i18next"
import { NavItem } from "reactstrap"

type TRepresentativeTab = {
    setActiveTab: Function
    activeTab: number
    formik: any
    index: number
    arrayHelpers: FieldArrayRenderProps
}

const RepresentativeTab = (props: TRepresentativeTab) => {
    const {setActiveTab, activeTab, formik, index, arrayHelpers} = props
    const {t} = useTranslation()

    return (
        <NavItem>
            <div className='d-flex'>
                <div onClick={() => setActiveTab(index)} className={activeTab === index ? 'form-tab-item form-tab-item--active' : "form-tab-item"} >
                    {formik.values.representatives?.[index]?.firstName || formik.values.representatives?.[index]?.lastName ?
                        `${formik.values.representatives?.[index]?.firstName || ""} ${formik.values.representatives?.[index]?.lastName || ""}`
                        : `${t("FORM_PDF.FORM.REPRESENTATIVE.DEFAULT_TAB_NAME")} ${index + 1}`
                    }
                </div>
                <div className='form-tab-item__delete' onClick={() => {
                    arrayHelpers.remove(index)
                    setActiveTab(activeTab > 0 ? activeTab - 1 : activeTab)
                }}>
                    X
                </div>
            </div>
        </NavItem>
    )
}

export default RepresentativeTab