import React, { Fragment } from "react"
import { ErrorMessage, FastField, Field } from "formik"
import { getRequest } from "../helper/apiCaller"
import ReactSelect from "react-select"
import { Label } from "reactstrap"
import { useEffect, useState, useCallback } from 'react'
import { getError } from "../validators";
import { TInputProps, TOption } from "../types/inputProps";

const AsyncSelectInput = (props: TInputProps) => {
    const { name, disabled, placeholder, label, depend, url, dependName, apiValueName, apiLabelName, options, clearList, shouldUpdate } = props

    const shoudUpdateProp = shouldUpdate ? {shouldUpdate: shouldUpdate} : {}; 

    if (depend) {
        return (
            <Field
                name={name}
                disabled={disabled}
                label={label}
                options={options || []}
                dependName={dependName || ""}
                apiValueName={apiValueName || ""}
                apiLabelName={apiLabelName || ""}
                url={url || ""}
                component={CustomAsyncSelectInput}
                clearList={clearList || []}
                {...shoudUpdateProp}
                placeholder={placeholder} />
        )
    } else {
        return (
            <FastField
                name={name}
                label={label}
                disabled={disabled}
                options={options || []}
                dependName={dependName || ""}
                apiValueName={apiValueName || ""}
                apiLabelName={apiLabelName || ""}
                clearList={clearList || []}
                url={url || ""}
                {...shoudUpdateProp}
                component={CustomAsyncSelectInput}
                placeholder={placeholder} />
        )
    }
}

export default AsyncSelectInput


const CustomAsyncSelectInput = ({
    field,
    form: { touched, errors, setFieldValue, values, setFieldTouched },
    ...props
}: any) => {
    const [options, setOptions] = useState(props.options)
    const [isLoading, setIsLoading] = useState(false)
    const [prevValue, setPrevValue] = useState<any>(null)

    const loadOptions = useCallback((item?: any) => {
        setIsLoading(true)
        if (props.apiLabelName === "") {
            setOptions(props.options)
            setIsLoading(false)
        } else {
            getRequest({
                url: `${props.url}/${item?.value || ""}`,
                then: (data: any) => {
                    let options: any = []
                    data.forEach((item: any) => {
                        options.push({ label: item[props.apiLabelName], value: item[props.apiValueName] })
                    })
                    setOptions(options)
                },
                onFinnaly: () => {
                    setIsLoading(false)
                }
            })
        }
    }, [props.url, props.apiValueName, props.apiLabelName, props.options]);

    const getDependValue = useCallback(() => {
        let pool: string[] = [props.dependName]
        if (props.dependName.includes(".")) pool = props.dependName.split(".")
        let inputValue = values
        pool.forEach((item) => {
            inputValue = inputValue?.[item]
        })
        return inputValue
    }, [props.dependName, values])

    useEffect(() => {
        if (values[props.dependName]) {
            loadOptions(values[props.dependName])
        }
        if (!props.dependName) {
            loadOptions()
        }
        if (props.dependName.includes(".")) {
            let value = getDependValue()
            if (value && prevValue !== value.value) {
                setPrevValue(value.value)
                loadOptions(value)
            }
        }
    }, [values[props.dependName.includes(".") ? props.dependName.split(".")[0] : props.dependName]])

    return (
        <div>
            {props.label !== "" ?
                <Label className="sfa-form__label">
                    {props.label}
                </Label>
                : <Fragment></Fragment>}
            <ReactSelect
                {...field}
                placeholder={props.placeholder}
                options={options}
                noOptionsMessage={({ inputValue }) => !inputValue ? "Brak dostępnych elementów" : "Brak pasujących elementów"}
                onBlur={() => setTimeout(() => { setFieldTouched(field.name), 100 })}
                isLoading={isLoading}
                isDisabled={props.dependName ? !getDependValue() : false}
                value={field.value ? options.find((option: TOption) => option.label.toLowerCase() === field.value?.label?.trim().toLowerCase()) || null : field.value}
                onChange={(option: any) => {
                    props.clearList.forEach((item: string) => {
                        if (item.includes("|string")) {
                            setFieldValue(item.split("|")[0], "")
                        } else {
                            setFieldValue(item, null)
                        }
                    })
                    setFieldValue(field.name, { label: `${option.label}`, value: `${option.value}` })
                }}
                className={getError(field, touched, errors) ? 'react-select-container sfa-form__input--error' : "react-select-container"}
                classNamePrefix="react-select"
            />
            <div className="sfa-form__error">
                <ErrorMessage name={field.name} />
            </div>
        </div>
    )
} 