import closeIcon from "assets/close-icon.svg"
import { Modal } from "react-bootstrap"
import { Spinner } from "reactstrap"

type TFormModal = {
    children: JSX.Element | JSX.Element[]
    isOpen: boolean
    setIsOpen: Function
    title: JSX.Element | string
    onEnter: Function
    isLoading: boolean
    size?: "lg" | "sm" | "xl"
}

const FormModal = (props: TFormModal) => {
    const { children, isOpen, setIsOpen, title, onEnter, isLoading, size } = props

    return (
        <Modal size={size} centered show={isOpen} onHide={() => setIsOpen(!isOpen)} onEnter={() => onEnter()}>
            <Modal.Body>
                <div className="form-modal__header">
                    <div className="form-modal__header-title">{title}</div>
                    <div className="form-modal__header-close cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                        <img src={closeIcon} alt="Close" />
                    </div>
                </div>
                <div className="form-modal__body">
                    {isLoading ?
                        <div>
                            <div className="text-center my-2">Ładowanie danych...</div>
                            <div className="d-flex justify-content-center"><Spinner /></div>
                            <div className="text-center my-2">Proszę czekać</div>
                        </div>
                        : <>{ children }</>
                    }
                </div>

            </Modal.Body>
        </Modal>
    )
}
export default FormModal