import React from "react"
import { ErrorMessage, FastField, Field } from "formik"
import { FormGroup, Input, Label } from "reactstrap"
import { TInputProps } from "../types/inputProps"
import { INPUT_TYPES } from "../helper/enums"

const SwitchInput = (props: TInputProps) => {
    const { name, disabled, label, labels, depend, shouldUpdate } = props

    const shoudUpdateProp = shouldUpdate ? {shouldUpdate: shouldUpdate} : {}; 

    if (depend) {
        return (
            <Field
                name={name}
                label={label}
                labels={labels}
                {...shoudUpdateProp}
                type={INPUT_TYPES.SWITCH}
                component={CustomSwitchInput}
                disabled={disabled} />
        )
    } else {
        return (
            <FastField
                name={name}
                label={label}
                labels={labels}
                {...shoudUpdateProp}
                type={INPUT_TYPES.SWITCH}
                component={CustomSwitchInput}
                disabled={disabled} />
        )
    }
}

export default SwitchInput

const getDinamicData = (item: string, values: any) => {
    let array = item.split("$")
    let tempItem = item
    array.forEach((abc) => {
        switch (abc.split(" ")[0]) {
            case "FULL_COMPNANY_NAME": tempItem = tempItem.replace("$FULL_COMPNANY_NAME", values.companyName || ""); break;
        }
    })
    return tempItem

}

const CustomSwitchInput = ({
    field,
    form: { values },
    ...props
}: any) => (
    <div className="switch__custome_style">
        <FormGroup switch>
            <Input
                style={{ width: "40px", height: "20px" }}
                checked={field.value}
                {...field}
                {...props}
                role="switch"
            />
            <Label className="sfa-form__label" check>
                {props.labels ? <div>
                    {props.label.split("<br />").map((item: any, index: number) =>
                        <span key={index}>{getDinamicData(item, values)}<br /></span>
                    )}
                    <ol>
                        {props.labels.split(';').map((item: string, index: number) =>
                            <li key={index}>
                                {item.split("|")[0]}
                                <ul>
                                    {item.split("|").filter((_i, index) => index !== 0).map((subItem, subIndex) =>
                                        <li key={subIndex}>
                                            {subItem.split("<br />").map((value, index) => <span key={index}>{value}<br /></span>)}
                                        </li>
                                    )}
                                </ul>
                            </li>
                        )}
                    </ol>
                </div> :
                    <div className="d-flex">
                        {props.label}
                    </div>
                }

            </Label>
        </FormGroup>

        <div className="sfa-form__error">
            <ErrorMessage name={field.name} />
        </div>
    </div>
)