import React, { Fragment } from "react"
import { ErrorMessage, FastField, Field } from "formik"
import ReactSelect from "react-select"
import { Label } from "reactstrap"
import { TInputProps } from "../types/inputProps"
import When from "../When"
import Tooltip from "../Tooltip"

const SelectInput = (props: TInputProps) => {
    const { name, disabled, options, placeholder, label, depend, isSearchable, shouldUpdate, tooltipText } = props

    const shoudUpdateProp = shouldUpdate ? { shouldUpdate: shouldUpdate } : {};

    if (depend) {
        return (
            <Field
                name={name}
                isSearchable={isSearchable === undefined ? true : isSearchable}
                label={label}
                {...shoudUpdateProp}
                tooltipText={tooltipText}
                component={CustomSelectInput}
                placeholder={placeholder}
                options={options || []}
                disabled={disabled} />
        )
    } else {
        return (
            <FastField
                name={name}
                label={label}
                {...shoudUpdateProp}
                tooltipText={tooltipText}
                isSearchable={isSearchable === undefined ? true : isSearchable}
                component={CustomSelectInput}
                placeholder={placeholder}
                options={options || []}
                disabled={disabled} />
        )
    }
}

export default SelectInput

const CustomSelectInput = ({
    field,
    form: { touched, errors, setFieldValue, setFieldTouched },
    ...props
}: any) => (
    <div>
        {props.label !== "" ?
            <Label className="sfa-form__label">
                {props.label}
                <When condition={props.tooltipText !== undefined}>
                    <Tooltip text={props.tooltipText}>
                    <div className="form-tootltip">i</div>
                    </Tooltip>
                </When>
            </Label>
            : <Fragment></Fragment>}
        <ReactSelect
            {...field}
            {...props}
            noOptionsMessage={({ inputValue }) => !inputValue ? "Brak dostępnych elementów" : "Brak pasujących elementów"}
            onBlur={() => setTimeout(() => { setFieldTouched(field.name), 100 })}
            isDisabled={props.disabled}
            value={props.options ?
                props.options.find((option: any) => option.value === field.value) ? props.options.find((option: any) => option.value === field.value)
                    : field.value : ""}
            onChange={(option: any) => setFieldValue(field.name, option)}
            className={touched[field.name] && errors[field.name] ? 'react-select-container sfa-form__input--error' : "react-select-container"}
            classNamePrefix="react-select"
        />
        <div className="sfa-form__error">
            <ErrorMessage name={field.name} />
        </div>
    </div>
)